<template>
  <div>
    <div
      class="header"
      style="position: fixed; left: 0; top: 0; background: #fff"
    >
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item itemActive">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link
          to="/ticket"
          style="
            position: absolute;

            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          "
        >
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img
            src="../assets/menpiao.png"
            alt=""
            style="
              width: 28rem;
              height: 20rem;
              z-indent: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            "
          />
        </div>
      </div>

      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div class="jing jing_pos" style="margin-top: 115rem">
      <img :src="jingSrc" alt="" class="jing_img" />
      <!--<div class="dahui">
				<div class="dahui_01">{{ $t("page3.tit1") }}</div>
				<div class="dahui_02">{{ $t("page3.tit1_1") }}</div>
			</div>
			<div class="zhenji">{{ $t("page3.tit2") }}</div>-->
    </div>
    <div
      style="
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        position: absolute;
        top: 345rem;
        right: 41rem;
        position: fixed;
      "
    >
      <router-link
        to="/contr"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div></router-link
      >
      <router-link
        v-if="false"
        to="/news"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div></router-link
      >
      <router-link
        to="/contributor"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div></router-link
      >
      <router-link
        to="/sponsor"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div></router-link
      >
      <router-link
        to="/ticket"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div></router-link
      >
      <router-link
        to="/lecture"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div></router-link
      >
    </div>

    <div class="baizhui">{{ $t("page3.item1.item1") }}</div>
    <div class="fenxiang">{{ $t("page3.item1.content") }}</div>
    <div class="fenxiang">
      {{ $t("page3.item1.item2") }}
    </div>
    <div class="fenxiang">{{ $t("page3.item1.item3") }}</div>
    <div class="fenxiang">{{ $t("page3.item1.item4") }}</div>

    <div class="conter">
      <div>
        <img src="../assets/shangj.png" alt="" />
        <p style="font-size: 16rem; color: #6a6a6a">
          {{ $t("page3.item2.item1") }}
        </p>
      </div>
      <div>
        <img
          src="../assets/you.png"
          alt=""
          style="
            padding-left: 111rem;
            padding-top: 21rem;
            width: 44rem;
            height: 22rem;
          "
        />
      </div>

      <div style="padding-left: 112rem">
        <img src="../assets/duihao.png" alt="" />
        <p style="font-size: 16rem; color: #6a6a6a">
          {{ $t("page3.item2.item2") }}
        </p>
      </div>
      <div>
        <img
          src="../assets/you.png"
          alt=""
          style="
            padding-left: 111rem;
            padding-top: 21rem;
            width: 44rem;
            height: 22rem;
          "
        />
      </div>
      <div style="padding-left: 112rem">
        <img src="../assets/rentou.png" alt="" />
        <p style="font-size: 16rem; color: #6a6a6a">
          {{ $t("page3.item2.item3") }}
        </p>
      </div>
      <div>
        <img
          src="../assets/you.png"
          alt=""
          style="
            padding-left: 111rem;
            padding-top: 21rem;
            width: 44rem;
            height: 22rem;
          "
        />
      </div>

      <div style="padding-left: 112rem">
        <img src="../assets/huatong.png" alt="" />
        <p style="font-size: 16rem; color: #6a6a6a">
          {{ $t("page3.item2.item3") }}
        </p>
      </div>
    </div>

    <div class="foot">
      <a class="ziliao" :href="collectSkip" target="_target">
        {{ $t("page3.item1.item1") }}</a
      >
    </div>

    <FooterCom />

    <div class="foot1" v-if="false">
      <div style="width: 100%; display: flex">
        <div>
          <div style="margin-left: 322rem; font-size: 14rem; margin-top: 83rem">
            {{ $t("bottom.item1.item1") }}
          </div>

          <div
            style="
              margin-left: 322rem;
              font-size: 12rem;
              color: #706f6f;
              margin-top: 8rem;
            "
          >
            {{ $t("bottom.item1.item2") }}
          </div>

          <div
            style="
              margin-left: 322rem;
              font-size: 12rem;
              color: #706f6f;
              margin-top: 9rem;
            "
          >
            {{ $t("bottom.item1.item3") }}
          </div>

          <div
            style="
              margin-left: 322rem;
              font-size: 14rem;
              color: #706f6f;
              margin-top: 12rem;
            "
          >
            {{ $t("bottom.item1.item4") }}
          </div>
        </div>

        <div>
          <div style="margin-left: 155rem; font-size: 14rem; margin-top: 83rem">
            <img
              src="../assets/si.jpg"
              alt=""
              style="width: 100rem; height: 100rem"
            />
          </div>

          <div
            style="
              margin-left: 170rem;
              font-size: 14rem;
              color: #706f6f;
              margin-top: 12rem;
            "
          >
            {{ $t("bottom.item8") }}
          </div>
        </div>

        <div>
          <div style="margin-left: 155rem; font-size: 14rem; margin-top: 83rem">
            <img
              src="../assets/wu.jpg"
              alt=""
              style="width: 100rem; height: 100rem"
            />
          </div>

          <div
            style="
              margin-left: 170rem;
              font-size: 14rem;
              color: #706f6f;
              margin-top: 12rem;
            "
          >
            {{ $t("bottom.item9") }}
          </div>
        </div>

        <div>
          <div style="margin-left: 155rem; font-size: 14rem; margin-top: 83rem">
            <img
              src="../assets/erweima.png"
              alt=""
              style="width: 100rem; height: 100rem"
            />
          </div>

          <div
            style="
              margin-left: 170rem;
              font-size: 14rem;
              color: #706f6f;
              margin-top: 12rem;
            "
          >
            {{ $t("bottom.item4") }}
          </div>
        </div>

        <div>
          <div style="margin-left: 155rem; font-size: 14rem; margin-top: 83rem">
            <img
              src="../assets/eriweimm.png"
              alt=""
              style="width: 100rem; height: 100rem"
            />
          </div>

          <div
            style="
              margin-left: 170rem;
              font-size: 14rem;
              color: #706f6f;
              margin-top: 12rem;
            "
          >
            {{ $t("bottom.item5") }}
          </div>
        </div>
      </div>
      <div class="youqing">
        <div>
          {{ $t("bottom.item6") }}:<a
            target="_target"
            href="https://www.iqalliance.org.cn/sy"
            >{{ $t("bottom.item7") }}</a
          >
        </div>
      </div>
      <!-- <div class="youqing" style="margin-top: 12rem">
				<div>{{ $t("bottom.item7") }}</div>
			</div> -->

      <div class="youqing1">
        <div>©2023 TiD Conference 版权所有</div>
      </div>
    </div>
  </div>
</template>
<script>
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    FooterCom,
  },
  data() {
    return {
      is_translate: false,
      collectSkip: "",
      // jingSrc: "http://123.56.97.68:8080/group1/M00/00/07/rBIAA2WeAXGABqusAAGEUEbYQvk43.webp",
      jingSrc: require("../assets/speechSolicitation.png"),
    };
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },
  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.getCollectSkip();
  },
  methods: {
    getCollectSkip() {
      this.$axios("third/page/collectSkip", "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);
          // this.collectSkip = data.address;
          this.collectSkip = "https://iudxo.xetsl.com/s/4CWi1q";
        })
        .catch((res) => {});
    },
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tag1 p {
  background-size: cover !important;
}
.tag1:hover div {
  display: block;
  background: #1f68eb;
}
.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}
.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}
.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}
.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}
.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}
.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}
.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}
.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}
.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}
.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}
.tag1:hover div {
  display: flex;
  background: #1f68eb;
}
.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}
.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}
.foot1 {
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}
.ziliao {
  display: block;
  font-size: 18rem;
  color: #ffff;
  text-align: center;
  line-height: 55rem;
}
.foot {
  // 	padding-left: 448rem;
  //    padding-top: 126rem;
  width: 210rem;
  height: 55rem;
  background-color: #496caa;
  border-radius: 25rem 25rem 25rem 25rem;
  // margin-left: 448rem;

  margin: 0 auto;
  margin-top: 126rem;
}
.conter {
  display: flex;
  padding-top: 89rem;
  padding-left: 448rem;
}
.fenxiang {
  width: 1320rem;
  word-break: break-all;
  font-size: 16rem;
  color: #6a6a6a;
  padding-left: 448rem;
  padding-top: 12rem;
}
.baizhui {
  font-size: 25rem;
  color: #355ca1;
  padding-top: 42rem;
  padding-left: 448rem;
}
.zhenji {
  padding-top: 102rem;
  font-size: 70rem;
  color: #ffffff;
  margin-left: 267rem;
}
.dahui {
  // display: flex;
  font-size: 70rem;
  color: #ffffff;
  margin-left: 462rem;
  padding-top: 53rem;
}
.jing {
  display: flex;
  //height: 300rem;
  // background: url(../assets/jing.png);
}

.yingwen {
  text-align: center;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
}
.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}
.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}
.item:hover {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
  color: red;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}
.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}
.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.itemActive {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
}
.itemActive a {
  // padding-top: 5rem;
  color: red !important;
}

// newCode
.jing_pos {
  position: relative;
  width: 100vw;
  min-height: 300rem;
  .dahui {
    margin-top: 24px;
  }
  .zhenji {
    margin-top: 24px;
  }
  .jing_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}
</style>
