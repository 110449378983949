<template>
	<div>
		<!-- 面包屑 -->
		<!-- <el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/home' }"
				>系统管理</el-breadcrumb-item
			>
			<el-breadcrumb-item><a href="/">角色列表</a></el-breadcrumb-item>
		</el-breadcrumb> -->

		<el-card align="left">
			<!-- 搜索框 -->

			<el-row :gutter="20">
				<el-col :span="7"
					><el-input
						placeholder="请输入内容"
						v-model="queryInfo.name"
						clearable
						@clear="getuserlist"
					>
						<el-button
							@click="searchbtn"
							slot="append"
							icon="el-icon-search"
						></el-button> </el-input
				></el-col>
				<el-col :span="4">
					<el-button type="primary" @click="addUserVisible = true"
						>添加角色</el-button
					>
				</el-col>
			</el-row>

			<el-table :data="userlist" border style="width: 100%" :header-cell-style="{ background: '#eaedf1',color:'black'}">
				<el-table-column prop="id" label="编号" width="329" align="center">
				</el-table-column>

				<el-table-column prop="name" label="角色" align="center"> </el-table-column>

				<el-table-column prop="" label="操作" width="329" align="center">
					<template slot-scope="scope">
						<el-button
							type="primary"
							icon="el-icon-edit"
							size="mini"
							@click="editUser(scope.row)"
						></el-button>

						<el-button
							type="danger"
							icon="el-icon-delete"
							size="mini"
							@click="remove(scope.row)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				@size-change="SizeChange"
				@current-change="CurrentChange"
				:current-page="queryInfo.pageNum"
				:page-sizes="[10, 20, 3, 4]"
				:page-size="queryInfo.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</el-card>

		<!-- 添加信息 -->
		<el-dialog
			title="添加用户信息"
			:visible.sync="addUserVisible"
			width="50%"
		>


		<el-form
				:model="addForm"
				:rules="addFormRules"
				ref="addFormRef"
				label-width="100rem"
			>
		


				<el-form-item label="角色：" prop="name">
					<el-input v-model="addForm.name"></el-input>
				</el-form-item>

			
			</el-form>
	
			<el-tree
				:data="data"
				show-checkbox
				ref="tree"
				node-key="id"
				:default-expanded-keys="[2, 3]"
				:default-checked-keys="[5]"
				:props="defaultProps"
			>
			</el-tree>

			<el-button @click="addUserVisible = false">取 消</el-button>
			<el-button type="primary" @click="editUserupdate">确 定</el-button>
		</el-dialog>

		<!-- //编辑 -->
		<el-dialog title="编辑信息" :visible.sync="adddVisible" width="50%">
			<el-tree
				:data="data"
				show-checkbox
				ref="editTree"
				node-key="id"
				:default-expanded-keys="[2, 3]"
				:default-checked-keys="checkedKeys"
				:props="defaultProps"
			>
			</el-tree>

		
			<el-button @click="adddVisible = false">取 消</el-button>
			<el-button type="primary" @click="uditUserupdate">确 定</el-button>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			queryInfo: {
				name: "",
				pageSize: 10,
				pageNum: 1,
			},
			userlist: [],
			//默认选中的节点
			checkedKeys: [],
			//当前数据总数
			total: 0,
			//添加显示影藏
			addUserVisible: false,
			//编辑
			addVisible: false,
			//表单数据
			addForm: {
			},
			editForm: {
				username: "",
				nickName: "",
				roleid: "",
				password: "",
			},
			//添加表单的验证规则
			addFormRules: {
				username: [
					{
						required: true,
						message: "请输入用户名字",
						trigger: "blur",
					},
					{
						min: 3,
						max: 10,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
				nickName: [
					{
						required: true,
						message: "请输入真实名字",
						trigger: "blur",
					},
					{
						min: 6,
						max: 15,
						message: "用户长度需要在6到15个字符之间",
						trigger: "blur",
					},
				],
				roleid: [
					{
						required: true,
						message: "请输入角色名字",
						trigger: "blur",
					},
					{
						min: 1,
						max: 3,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
				password: [
					{
						required: true,
						message: "请输入角色名字",
						trigger: "blur",
					},
					{
						min: 1,
						max: 3,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
			},
			//存储获取到的额信息
			editUserpams: {},
			//角色列表数据
			roleList: [],
			rolelist: [],
			data: [],

			defaultProps: {
				children: "children",
				label: "title",
			},
			adddVisible: false,
			roleList: [],
		};
	},
	methods: {
		//获取角色列表
		getRoleList() {
			this.$axios("/user/CdRole/listAll", "get")
				.then((res) => {
					console.log(res);
					this.roleList = res.data.data;
				})
				.catch((res) => {});
		},
		getuserlist() {
			this.$axios("/user/CdRole/list", "get", this.queryInfo)
				.then((res) => {
					console.log(res);
					if (res.data.code !== 200) this.$message.error("失败");
					this.total = res.data.data.total;
					this.userlist = res.data.data.list;
					console.log(this.userlist);
				})
				.catch((err) => {
					console.log(err);
				});
		},

		//当每页数据发生变化时候触发
		SizeChange(newsize) {
			this.queryInfo.pageSize = newsize;
			this.getuserlist();
		},
		//当前页码发生变化时候触发
		CurrentChange(newNum) {
			this.queryInfo.pageNum = newNum;
			this.getuserlist();
		},
		//添加角色
		adduser() {
			this.$axios("/admin/add", "post", this.addForm)
				.then((res) => {
					console.log(res);
					this.addUserVisible = !this.addUserVisible;
					this.getuserlist();
				})

				.catch((err) => {
					console.log(err);
				});
		},
		// 编辑信息
		editUser(row) {
			this.addForm = row;
			this.$axios("/user/CdRole/listMenuIds?roleId=" + row.id, "get")
				.then((res) => {
					this.checkedKeys = res.data.data;
					this.adddVisible = !this.adddVisible;
					// 过500毫秒调用
					setTimeout(() => {
						// 方法区
						this.$refs.editTree.setCheckedKeys(this.checkedKeys);
					}, 200);
				})

				.catch((err) => {
					console.log(err);
				});
		},
		//添加的确定
		editUserupdate() {
			var params = this.$refs.tree.getCheckedKeys();
			console.log(this.addForm);
			this.$axios(
				"/user/CdRole/allocMenu?menuIds=" + params,
				"post",
				this.addForm
			)
				.then((res) => {
					console.log(res);
					this.addUserVisible = !this.addUserVisible;
					this.getuserlist();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		//编辑的确定
		uditUserupdate() {
			var params = this.$refs.editTree.getCheckedKeys();
			console.log(this.addForm);
			this.$axios(
				"/user/CdRole/allocMenu?menuIds=" + params,
				"post",
				this.addForm
			)
				.then((res) => {
					console.log(res);
					this.adddVisible = !this.adddVisible;
					this.getuserlist();
				})
				.catch((err) => {
					console.log(err);
				});
		},

		//删除
		remove(row) {
			//    const that = this;
			this.$confirm("是否删除该角色", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$axios(
						"/user/CdRole/delete?id= " + row.id,
						"post"
						// this.editForm,
					).then(() => {
						this.getuserlist();
					});
				})
				.catch(() => {});
		},
		//查询
		searchbtn() {
			this.$axios("/user/CdRole/list", "get", this.queryInfo).then(
				(res) => {
					// console.log(res);
					if (res.data.code !== 200) this.$message.error("失败");
					this.userlist = res.data.data.list;
					this.total = res.data.data.total;
					console.log(this.userlist);
				}
			);
		},
		//角色列表数据
		getRoleList() {
			this.$axios("/user/CdRole/listMenu", "get")
				.then((res) => {
					console.log(res);
					if (res.data.code !== 200) this.$message.error("失败");

					this.data = res.data.data;
					console.log(this.data);
				})
				.catch((res) => {});
		},
	},

	created() {
		var that = this;
		this.getuserlist();
		//角色列表数据
		this.getRoleList();
	},
};
</script>
<style lang=""></style>
