<template>
	<div>
		<div class="yingwen">
			<!-- <div style="font-size: 44rem; color: #e4e9f2">
				Micro distributed architecture full
			</div>
			<div style="font-size: 44rem; color: #e4e9f2">
				stack monitoring service
			</div> -->
			<div style="font-size: 50rem; color: #355ca1; margin-top: -65rem">
				{{conference_subject}}
			</div>
		
		</div>

		<div style="display: flex;margin-top: 30rem;">
			<div class="ruyuan center">
					<div class="ruyuan1 center" >
			<img
				:src="personnel_img_path"
				alt="图片"
				onerror="this.src='http://123.56.97.68:8080/group1/M00/00/03/rBIAA2SJdTaANrT-AAAQwmtTfuk87.webp';this.οnerrοr=null"
				class="tupian"
			/>
			</div>
			</div>
			<div
				style="flex: 1;  margin-left: 39rem;"
				class=" descTitle"
			>
			<div style="display:flex;">
						<span style="display:inline-block;width: 86rem;">{{$t("DetailDialog.item1")}}:</span>
						<div style="flex:1;margin-left:30rem;">
							<div style="display:inline-block;">{{ subject  }}</div> 
						</div>
					</div>
				<!-- <div style="width: 50%; float: left">
					{{$t("to="/news"
v-if="false"")}}{{ conference_address }}
				</div> -->

				<!-- <div style="width: 50%; float: left; margin-top: 21rem">
					案例来源： {{ case_come }}
				</div> -->

				<!-- <div style="margin-top: 20rem;">
					<div style="display:inline-block;">{{$t("DetailDialog.item3")}}</div>&nbsp;&nbsp;&nbsp;{{schedule_time}}&nbsp; {{ conference_time }}
				</div> -->
				<div style="display:flex;margin-top: 20rem;">
						<span style="display:inline-block;width: 86rem;">{{$t("DetailDialog.item3")}}:</span>
						<div style="flex:1;margin-left:30rem;">
							<div style="display:inline-block;">{{schedule_time}}&nbsp; {{ conference_time }}</div> 
						</div>
					</div>

				<div style="margin-top: 20rem">
					<div style="display:flex;">
						<span style="display:inline-block;width: 86rem;">{{$t("DetailDialog.item6")}}: </span>
						<div style="flex:1;margin-left:30rem;">
							<div style="display:inline-block;">{{personnel_name}}</div> 
						<span 
							>&nbsp;&nbsp;{{ personnel_position }}</span
						>
						</div>
					</div>
				</div>
				<div
					style=" margin-top: 15rem;color:#6a6a6a"
					
				>
					{{personnel_introduce}}
				</div>
				<div >
					<div style="margin-top: 30rem;
   " class="descTitle">{{$t("DetailDialog.item4")}}</div>
					<div class="desc"  style="margin-top: 15rem" v-html="course_summary">
					</div>

					<div class="descTitle" style="margin-top: 30rem">{{$t("DetailDialog.item5")}}</div>
					<div v-html="speech_title" class="desc clearfix" style="margin-top: 5rem;">
					</div>
				</div>

				
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			subject: "",
			conference_address: "",
			conference_time: "",
			personnel_position: "",
			course_summary: "",
			case_come: "",
			speech_title: "",
			personnel_img_path:"",
			personnel_name:"",
			schedule_time:""
		};
	},

	props:{
		id:0,
		is_translate:false,
	},
	computed:{
		
		
		
		parentId:function(){
			return this.id
		},
		
        
    },
     
	watch:{
		parentId(newName, oldName){
			this.scheduleDetail(newName);
		}
	},
	created() {
		if(sessionStorage.getItem("language")== 'en'){
			this.is_translate = true;
		}
		this.scheduleDetail(this.id);
	},
	methods: {
		//主题
		scheduleDetail(id) {
			// var id = 18;
			this.$axios("/detail/page/scheduleDetail?is_translate="+this.is_translate+"&id=" + id, "get")
				.then((res) => {
					let data = res.data.data;
					console.log(res);
					this.conference_subject = data.conference_subject;
					this.personnel_introduce =data.personnel_introduce;       
					this.personnel_name = data.personnel_name;
					this.subject = data.subject;
					this.conference_address = data.conference_address;
					this.conference_time = data.conference_time;
					this.personnel_position = data.personnel_position;
					this.course_summary = data.course_summary;
					this.case_come = data.case_come;
					this.speech_title = data.speech_title;
					this.personnel_img_path=data.personnel_img_path;
					this.schedule_time = data.schedule_time;
				})
				.catch((res) => {});
		},
	},
};
</script>
<style lang="less" scoped>
.clearfix:before,
.clearfix:after {
	content: "   ";
	display: table;
	
}
.descTitle {
	font-size: 20rem;
	color: #355ca1;
	line-height: 1.2;
	
	
	
}
.float {
	line-height: 0;
}
.desc {
	font-weight: normal;
	color: #6a6a6a;
	font-size: 16rem;
	line-height: 24rem;
	
}
.tupian {
	width: 220rem;
	height: 220rem;
	border-radius: 50%;
	
}
.yingwen {
	text-align: center;
	margin-top: 30rem;
	position: relative;
}

.header {
	height: 115rem;
	// background-color: pink;
	display: flex;
}
.imger {
	width: 116rem;
	height: 79rem;
	margin-left: 321rem;
	margin-top: 18rem;
}
.header_png {
	width: 757rem;
	justify-content: space-around;
	display: flex;
	margin-top: 45rem;
	font-size: 20rem;
}
.item:hover {
	// padding-top: 5rem;
	border-bottom: 2rem solid red;
	color: red;
}
.juxingimg {
	width: 139rem;
	height: 41rem;
	margin-left: 88rem;
	margin-top: 37rem;
}
.biankuang {
	width: 139rem;
	height: 41rem;
	margin-top: 37rem;
	margin-left: 131rem;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
	display: flex;
}
.yuyanimg {
	margin-left: 8rem;
	width: 25rem;
	height: 25rem;
	margin-top: 7rem;
}
.input {
	width: 139rem;
	height: 41rem;
	background-color: #efefef;
	border-radius: 25rem 25rem 25rem 25rem;
	position: absolute;
	right: 403rem;
	top: 32rem;
}
.input1 {
	width: 25rem;
	height: 25rem;

	margin-top: 9rem;
	margin-left: 22rem;
}
.gianbi {
	font-size: 18rem;
	margin-top: -25rem;
	margin-left: 26rem;
	color: #496caa;
}

.ruyuan {
	width: 240rem;
	height: 240rem;
	// border-radius: 50%;
	background: url(../assets/ruyuan.png) no-repeat;
	background-size: 100%;
}
.center{
	 display: flex;
    justify-content: center;
    align-items: center;
}
.ruyuan1{
	    background: #fff;
    border-radius: 50%;
   height: 227rem;
    width: 227rem;

}
</style>
