<template>
	<div>
		<div class="header">
			<div><img src="../assets/toubu.png" alt="" class="imger" /></div>

			<div class="header_png">
				<div class="item" >首页</div>
				<div class="item" >大会日程</div>
				<div class="item">演讲征集</div>
				<div class="item">历届回顾</div>
			</div>

			<div>
				<img src="../assets/juxing.png" alt="" class="juxingimg" />
				<router-link to="/ticket"
					style="
						    position: absolute;

    top: 45rem;
    right: 70rem;
    color: white;
    font-size: 16rem;
					"
				>
					购票
				</router-link>
				<div>
					<img
						src="../assets/menpiao.png"
						alt=""
						style="
							position: absolute;
							width: 28rem;
							height: 20rem;
							z-indent: 2;
							top: 48rem;
							right: 603rem;
							color: white;
						"
					/>
				</div>
			</div>

			<div class="biankuang">
				<div
					style="
						font-size: 14rem;
						margin-left: 21rem;
						margin-top: 8rem;
						color: #242424;
					"
				>
					English
				</div>
				<img src="../assets/yuyan.png" alt="" class="yuyanimg" />
			</div>
		</div>

		<div class="yingwen">
			<div style="font-size: 44rem; color: #e4e9f2">
				Micro distributed architecture full
			</div>
			<div style="font-size: 44rem; color: #e4e9f2">
				stack monitoring service
			</div>
			<div style="font-size: 50rem; color: #355ca1; margin-top: -100rem">
				微服务分布式架构全栈监控
			</div>
			<div class="input">
				<div class="input1">
					<img src="../assets/guanbi.png" alt="" />
				</div>
				<div class="gianbi">关闭</div>
			</div>
		</div>

		<div style="display: flex; align-items: center">
			<img
				src="http://101.42.8.116:8080/group1/M00/00/01/rBEAA2QaYmOAOxQ3AAC_uktxnNU675.png"
				alt=""
				class="tupian"
			/>
			<div
				style="flex: 0 1 auto; width: 1024rem; margin-left: 39rem"
				class="clearfix descTitle"
			>
				<div style="width: 50%; float: left">专题： {{ subject }}</div>
				<div style="width: 50%; float: left">
					会议地点：{{ conference_address }}
				</div>

				<div style="width: 50%; float: left; margin-top: 21rem">
					案例来源： {{ case_come }}
				</div>

				<div style="width: 50%; float: left; margin-top: 21rem">
					分享时间： {{ conference_time }}
				</div>

				<div style="width: 100%; float: left; margin-top: 32rem">
					<div>
						王吐音&nbsp;&nbsp;&nbsp;&nbsp;<span
							style="font-size: 16rem"
							>{{ personnel_position }}</span
						>
					</div>
				</div>
				<div
					style="width: 100%; float: left; margin-top: 12rem"
					
				>
					Apache软件基金会董事，Apache SkyWalking创始人，Apache
					APISIX，ECharts，ShardingSphere，DolphinScheduler项目PMC成员。
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			subject: "",
			conference_address: "",
			conference_time: "",
			personnel_position: "",
			course_summary: "",
			case_come: "",
			speech_title: "",
		};
	},
	props:{
		id:null
	},
	created() {
		if(sessionStorage.getItem("language")== 'en'){
			this.is_translate = true;
		}
		alert(this.id);
		this.scheduleDetail(this.id);
	},
	methods: {
		//主题
		scheduleDetail(id) {
			 var id = 18;
			this.$axios("/detail/page/scheduleDetaili?d=" + id, "get")
				.then((res) => {
					let data = res.data.data;
					console.log(res);

					this.subject = data.subject;
					this.conference_address = data.conference_address;
					this.conference_time = data.conference_time;
					this.personnel_position = data.personnel_position;
					this.course_summary = data.course_summary;
					this.case_come = data.case_come;
					this.speech_title = data.speech_title;
				})
				.catch((res) => {});
		},
	},
};
</script>
<style lang="less" scoped>
.clearfix:before,
.clearfix:after {
	content: "   ";
	display: table;
}
.descTitle {
	font-size: 20rem;
	color: #355ca1;
	margin-left: 240rem;
}
.float {
	float: left;
	width: 50%;
	line-height: 0;
}
.desc {
	font-weight: normal;
	color: #6a6a6a;
	font-size: 16rem;
	margin-left: 237rem;
}
.tupian {
	width: 220rem;
	height: 220rem;
	margin-left: 318rem;
}
.yingwen {
	text-align: center;
	position: relative;
}

.header {
	width: 1920rem;
	height: 115rem;
	// background-color: pink;
	display: flex;
}
.imger {
	width: 116rem;
	height: 79rem;
	margin-left: 321rem;
	margin-top: 18rem;
}
.header_png {
	width: 757rem;
	justify-content: space-around;
	display: flex;
	margin-top: 45rem;
	font-size: 20rem;
}
.item:hover {
	// padding-top: 5rem;
	border-bottom: 2px solid red;
	color: red;
}
.juxingimg {
	width: 139rem;
	height: 41rem;
	margin-left: 88rem;
	margin-top: 37rem;
}
.biankuang {
	width: 139rem;
	height: 41rem;
	margin-top: 37rem;
	margin-left: 131rem;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
	display: flex;
}
.yuyanimg {
	margin-left: 8rem;
	width: 25rem;
	height: 25rem;
	margin-top: 7rem;
}
.input {
	width: 139rem;
	height: 41rem;
	background-color: #efefef;
	border-radius: 25rem 25rem 25rem 25rem;
	position: absolute;
	right: 403rem;
	top: 32rem;
}
.input1 {
	width: 25rem;
	height: 25rem;

	margin-top: 9rem;
	margin-left: 22rem;
}
.gianbi {
	font-size: 18rem;
	margin-top: -25rem;
	margin-left: 26rem;
	color: #496caa;
}
</style>
