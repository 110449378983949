<template>
  <div>
    <div
      class="header"
      style="position: fixed; left: 0; top: 0; background: #fff"
    >
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link
          to="/ticket"
          style="
            position: absolute;
            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          "
        >
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img
            src="../assets/menpiao.png"
            alt=""
            style="
              position: absolute;
              width: 28rem;
              height: 20rem;
              z-indent: 2;
              top: 48rem;
              right: 20rem;
              color: white;
            "
          />
        </div>
      </div>

      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div class="jing jing_pos" style="margin-top: 115rem">
      <img :src="base_img" alt="" class="jing_img" />
      <div class="dahui">
        <div>{{ $t("ticket.tit1") }}</div>
        <div class="piaowu">{{ $t("ticket.tit2") }}</div>
        <div class="piaowu1">{{ $t("ticket.tit3") }}</div>
      </div>
    </div>

    <div
      style="
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        top: 345rem;
        right: 41rem;
        position: fixed;
        z-index: 1111111;
      "
    >
      <router-link
        to="/contr"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div></router-link
      >
      <router-link
        v-if="false"
        to="/news"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div></router-link
      >
      <router-link
        to="/contributor"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div></router-link
      >
      <router-link
        to="/sponsor"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div></router-link
      >
      <router-link
        to="/ticket"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div></router-link
      >
      <router-link
        to="/lecture"
        class="tag1"
        style="position: relative; display: block"
      >
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div></router-link
      >
    </div>

    <!-- mpItem:{
			item1:"截止时间",
			item2:"5月31日前",
			item3:"6月30日前",
			item4:"会前",
		},
		mp:"大会门票",
		qy:"门票权益",
		gp:"参会购票",
		item1:"价目表",
		course1:"大时段并行课程",
		course1_time:"(8月13日-14日)",
		course2:"全体大会",
		course2_time:"(8月15日上午)",
		course3:"专题会",
		course3_time:"(8月15日下午-16日)", -->
    <!-- 		<div class="table">
			<div class="tableHeader">{{ $t("ticket.mp") }}</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.mpItem.item1") }}</div>
				<div class="tableCol">{{ $t("ticket.mpItem.item2") }}</div>
				<div class="tableCol">{{ $t("ticket.mpItem.item3") }}</div>
				<div class="tableCol">{{ $t("ticket.mpItem.item4") }}</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item1") }}</div>
				<div class="tableCol blue">￥4600</div>
				<div class="tableCol blue">￥5500</div>
				<div class="tableCol blue">￥6600</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item2") }}</div>
				<div class="tableCol blue">￥2400</div>
				<div class="tableCol blue">￥2900</div>
				<div class="tableCol blue">￥3500</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item3") }}</div>
				<div class="tableCol blue">￥3100</div>
				<div class="tableCol blue">￥3700</div>
				<div class="tableCol blue">￥4500</div>
			</div>
		</div> -->

    <!-- 大时段并行课程",
      // item3:"8月15日全体大会",
      // item4:"8月16日并行专题会", -->
    <!-- 		<div class="table">
			<div class="tableHeader">{{ $t("ticket.qy") }}</div>
			<div class="tableRow">
				<div class="tableCol"></div>
				<div class="tableCol">
					<div class="t1">{{ $t("ticket.course1") }}</div>
					<div class="t2">{{ $t("ticket.course1_time") }}</div>
				</div>
				<div class="tableCol">
					<div class="t1">{{ $t("ticket.course2") }}</div>
					<div class="t2">{{ $t("ticket.course2_time") }}</div>
				</div>
				<div class="tableCol">
					<div class="t1">{{ $t("ticket.course3") }}</div>
					<div class="t2">{{ $t("ticket.course3_time") }}</div>
				</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item1") }}</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item2") }}</div>
				<div class="tableCol blue">--</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
			</div>
			<div class="tableRow">
				<div class="tableCol">{{ $t("ticket.item2.item3") }}</div>
				<div class="tableCol blue">
					<img src="../assets/duigou.png" alt="" />
				</div>
				<div class="tableCol blue">--</div>
				<div class="tableCol blue">--</div>
			</div>
		</div> -->

    <div class="table" v-if="false">
      <div class="tableHeader">{{ $t("ticket.gp") }}</div>
    </div>

    <!-- <div class="jiamubiao">{{$t("ticket.item1")}}</div> -->
    <!-- 
		<div class="zhongjan">
			<div class="con" style="margin-left: 315rem">

            </div>
			<div class="con"  style="margin-left: 11rem">
                <span class="piao" >{{$t("ticket.item2.item1")}}</span><br>
                <span class="piao1"><span style="color:#1F68EB">￥4,600</span><span>(￥6,600)</span></span><br>
              
            </div>
			<div class="con"  style="margin-left: 11rem">
                <span class="piao" >{{$t("ticket.item2.item2")}}</span><br>
                <span class="piao1"><span style="color:#1F68EB">￥2,400</span><span>(￥3,500)</span></span><br>
            </div>
			<div class="con"  style="margin-left: 11rem">
                <span class="piao" >{{$t("ticket.item2.item3")}}</span><br>
                <span class="piao1"><span style="color:#1F68EB">￥3,100</span><span>(￥4,500)</span></span><br>
            </div>
		</div> -->
    <!-- <div class="zhongjan1">
			<div class="con1" style="margin-left: 315rem">{{$t("ticket.row.item1")}}</div>
			<div class="con1"  style="margin-left: 11rem">
                <img src="../assets/duigou.png" alt="">
            </div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
		</div>

         <div class="zhongjan1">
			<div class="con1" style="margin-left: 315rem">{{$t("ticket.row.item2")}}</div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
		</div>

         <div class="zhongjan1">
			<div class="con1" style="margin-left: 315rem">{{$t("ticket.row.item3")}}</div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
		</div>

         <div class="zhongjan1">
			<div class="con1" style="margin-left: 315rem">{{$t("ticket.row.item4")}}</div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
		</div> -->

    <!-- <div class="zhongjan1">
			<div class="con1" style="margin-left: 315rem">{{$t("ticket.row.item5")}}</div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
			<div class="con1"  style="margin-left: 11rem"> <img src="../assets/duigou.png" alt=""></div>
		</div> -->
    <!-- <div class="shuoming">
			<div style="margin-left: 315rem; font-size: 26rem; color: #355ca1">
				{{ $t("ticket.item3.item1") }}：
			</div>
			<div style="font-size: 20rem; color: #355ca1; padding-top: 5rem">
				{{ $t("ticket.item3.text1") }}
			</div>

			<div></div>
		</div>

		<div class="shuoming" style="margin-top: 20rem">
			<div style="margin-left: 315rem; font-size: 26rem; color: #355ca1">
				{{ $t("ticket.item3.item2") }}：
			</div>
			<div style="font-size: 20rem; color: #355ca1; padding-top: 5rem">
				{{ $t("ticket.item3.text2") }}
			</div>
		</div>

		<div class="shuoming" style="margin-top: 20rem">
			<div style="margin-left: 315rem; font-size: 26rem; color: #355ca1">
				{{ $t("ticket.item3.item3") }}：
			</div>
			<div
				style="
					font-size: 20rem;
					color: #355ca1;
					padding-top: 5rem;
					width: 300rem;
				"
			>
				{{ $t("ticket.item3.text3") }}
			</div>
			<div
				style="
					font-size: 20rem;
					color: #355ca1;
					padding-top: 5rem;
					width: 300rem;
					margin-left: 91rem;
				"
			>
				{{ $t("ticket.item3.text3_1") }}
			</div>
			<div><img src="../assets/erwei.png" alt="" /></div>
		</div>
		<a class="ziliao" :href="ticketSkip" target="_target">{{
			$t("ticket.item3.item5")
		}}</a>
		<div class="xuxian"></div>

		<div class="dibu">
			<div style="font-size: 26rem; color: #355ca1">
				{{ $t("ticket.item3.item4") }}
			</div>
			<img :src="dibu" alt="" style="margin-top: 10rem" />
		</div> -->
    <div class="other_bug_ticket">
      <img :src="ticketSrc" alt="" class="ticker_img" @click="navTo" />
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    FooterCom,
  },
  data() {
    return {
      ticketSkip: "",
      is_translate: false,
      dibu: "http://123.56.97.68:8080/group1/M00/00/07/rBIAA2WNAUyAClvJAAE-kKMHoVw20.webp",
      // base_img: require("../assets/banner1.jpg"),
      base_img: require("../assets/购票banner.jpg"),
      // base_img: "http://123.56.97.68:8080/group1/M00/00/07/rBIAA2WbuFKASuwiAAaF2AYDPeM17.webp",
      ticketSrc: require("../assets/page.png"),
      // ticketSrc: "http://123.56.97.68:8080/group1/M00/00/07/rBIAA2WcpImADOisAAaqzNjaUfs34.webp",
    };
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },
  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.getticketSkip();
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },

  methods: {
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
    getticketSkip() {
      this.$axios("third/page/ticketSkip", "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);
          this.ticketSkip = data.address;
        })
        .catch((res) => {});
    },
    navTo() {
      // window.open("https://iudxo.xetsl.com/s/1wV9pR");
      window.open(
        "https://appeskobmvs7059.h5.xiaoeknow.com/p/decorate/more/eyJpZCI6MTgwNjg4NTMsImNoYW5uZWxfaWQiOiIiLCJjb21wb25lbnRfaWQiOjMxOTE4MzQyfQ"
      );
    },
  },
};
</script>
<style lang="less" scoped>
// .xuxian{
// 	height: 4rem;
// 	width: 2rem;
// 	color: red;
// }
.ziliao {
  display: block;
  font-size: 18rem;
  color: #ffff;
  text-align: center;
  line-height: 55rem;
  background: blue;
  border-radius: 20rem;
  width: 141rem;
  margin: 0 auto;
}
.tag1 p {
  background-size: cover !important;
}
.tag1:hover div {
  display: block;
  background: #1f68eb;
}
.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}
.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}
.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}
.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}
.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}
.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}
.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}
.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}
.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}
.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}
.tag1:hover div {
  display: flex;
  background: #1f68eb;
}
.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}
.piao1 {
  font-size: 26rem;
  color: #6a6a6a;
  line-height: 40rem;
  margin-left: 53rem;
}
.piao2 {
  text-align: center;
  font-size: 18rem;
  color: #6a6a6a;
  margin-left: 133rem;
  line-height: 40rem;
}
.piao {
  text-align: center;
  font-size: 24rem;
  color: #6a6a6a;
  line-height: 60rem;
  margin-left: 133rem;
}
.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}

.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}
.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}
.dibu {
  margin-left: 1418rem;
  margin-top: -290rem;
  position: relative;
  img {
    width: 156rem;
  }
}
.dibu::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -134rem;
  bottom: 0;
  width: 0rem;
  /* height: 0; */
  border: 20rem solid;
  margin: 0 auto;
  border: 2rem dashed #1a6bf2;
}
.shuoming {
  display: flex;
  margin-left: -49rem;
  margin-top: 56rem;
}
.con {
  width: 343rem;
  height: 155rem;
  background-color: #ebebeb;
}
.con1 {
  width: 343rem;
  height: 81rem;
  background-color: #ebebeb;
  line-height: 81rem;
  text-align: center;
  color: #6a6a6a;
  font-size: 18rem;
}
.zhongjan {
  display: flex;
  margin-top: 41rem;
}
.zhongjan1 {
  display: flex;
  margin-top: 10rem;
}

.jiamubiao {
  font-size: 42rem;
  color: #355ca1;
  text-align: center;
  margin-top: 37rem;
}
.piaowu {
  font-size: 65rem;
  color: #ffff;
  text-align: center;
}
.piaowu1 {
  font-size: 27rem;
  color: #ffff;
  text-align: center;
  margin-top: 121rem;
}
.header {
  width: 1920rem;
  height: 115rem;

  display: flex;
}
.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}
.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}
.item:hover {
  border-bottom: 2px solid red;
  color: red;
}
.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}
.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}
.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.jing {
  display: flex;
  // width: 1920rem;
  height: 588rem;
  // background: url(../assets/goupiao.png);
  // background: url(../assets/banner1.jpg);
  object-fit: cover;
}

.dahui {
  font-size: 84rem;
  color: #ffffff;
  margin-left: 462rem;
  padding-top: 90rem;
}

.table {
  width: 1436rem;
  margin: 37rem auto 0rem;
  display: flex;
  flex-wrap: wrap;
}
.tableRow,
.tableHeader {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 81rem;
  line-height: 81rem;
  background-color: #ebebeb;
  text-align: center;
  font-size: 18rem;
  color: #333;
}
.tableHeader {
  font-size: 33rem;
  // color: #355ca1;
  color: #fff;
  background: rgb(26, 107, 242);
}

.tableCol {
  border: 1px solid #ddd;
  flex: 1;
  .t1 {
    height: 41rem;
    line-height: 55rem;
  }
  .t2 {
    height: 30rem;
    line-height: 20rem;
    font-size: 16rem;
  }
}
.blue {
  color: rgb(102, 157, 255);
}

.jing_pos {
  position: relative;
  width: 100vw;
  .jing_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.other_bug_ticket {
  width: 1436rem;
  margin: 37rem auto 0rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  .ticker_img {
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 1436rem;
    height: auto;
    object-fit: cover;
  }
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}
</style>
