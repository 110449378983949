<template>
  <div>
    <div
      class="header"
      style="position: fixed; left: 0; top: 0; background: #fff"
    >
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>
      <!-- header -->
      <div class="header_png">
        <div class="item itemActive">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
      </div>
      <!-- 右侧导航 -->
      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link
          to="/ticket"
          style="
            position: absolute;

            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          "
        >
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img
            src="../assets/menpiao.png"
            alt=""
            style="
              width: 28rem;
              height: 20rem;
              z-indent: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            "
          />
        </div>
      </div>
      <!-- 翻译 -->
      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div
          style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          "
        >
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <!-- :style="{
				background: 'url(' + base_img + ') no-repeat',
				'background-size': cover,
			}" -->
    <div class="first first_pos" style="margin-top: 115rem">
      <!--<img :src="base_img" alt="" class="first_img"/>-->
      <!--<img :src="bgc" alt="" class="first_img" />-->
      <img :src="bannerSrc" alt="" class="first_img" />
      <div
        style="
          width: 68rem;
          color: rgb(255, 255, 255);
          line-height: 45rem;
          position: absolute;
          top: 345rem;
          right: 41rem;
          position: fixed;
        "
      >
        <router-link
          to="/contr"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>
          <!-- <img src="../assets/onw.png" alt="" /> -->
          <div>{{ $t("right.item8") }}</div>
        </router-link>
        <router-link
          v-if="false"
          to="/news"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>

          <div>{{ $t("right.item1") }}</div>
        </router-link>

        <router-link
          to="/contributor"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>

          <div>{{ $t("right.item2") }}</div>
        </router-link>
        <router-link
          to="/sponsor"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>
          <div>{{ $t("right.item3") }}</div>
        </router-link>
        <router-link
          to="/ticket"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>

          <div>{{ $t("right.item4") }}</div>
        </router-link>
        <router-link
          to="/lecture"
          class="tag1"
          style="position: relative; display: block"
        >
          <p style="width: 68rem; height: 68rem"></p>

          <div>{{ $t("right.item5") }}</div>
        </router-link>
      </div>
    </div>

    <div class="conter">
      <div
        style="color: #f3f5f9; line-height: 0.7; height: 0; font-size: 81rem"
      >
        The Presidium Of The Conference
      </div>
      <div class="zhuxi">{{ $t("page1.content1") }}</div>
    </div>

    <!--  大会主席团 -->
    <div class="conterter">
      <div class="item1" v-for="presidium in presidiums">
        <!-- <div>
            <img src="/assets/ruyuan.png" alt="">
          </div> -->
        <div class="ruyuan" @click="selectZhuXi(presidium.id)">
          <div class="ruyuan" style="height: 210rem">
            <div
              style="
                background-color: #ffff;
                width: 195rem;
                padding-top: 3rem;
                height: 195rem;
                margin-left: 4rem;
                border-radius: 50%;
              "
            >
              <img
                :src="presidium.personnel_img_path"
                alt=""
                style="
                  width: 188rem;
                  height: 190rem;
                  padding-top: 1rem;
                  border-radius: 50%;
                "
              />
            </div>
          </div>
          <p style="color: #355ca1; font-size: 22rem">
            {{ presidium.personnel_name }}
          </p>
          <!--        <p>中国敏捷教育培训企业</p>-->
          <p
            class="shenglve"
            style="
              color: #355ca1;
              font-size: 16rem;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            "
          >
            {{ presidium.company }}
          </p>
          <p
            class="shenglve"
            style="
              color: #355ca1;
              font-size: 16rem;
              font-weight: 500;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            "
          >
            {{ presidium.personnel_position }}
          </p>
        </div>
      </div>
    </div>

    <!-- 大会日程开始 -->

    <div class="contert">
      <div class="zhuxi0">Conference Schedule</div>

      <div class="zhuxi">{{ $t("page1.content2") }}</div>
    </div>

    <div class="day">
      <div
        style="
          width: 1200rem;
          margin: auto;
          display: flex;
          margin-top: 18rem;
          justify-content: space-around;
        "
      >
        <div v-for="(dayitem, i) in days">
          <div
            @click="dayClick(i)"
            :class="{
              'riqi active': Number(i) == index,
              riqi: Number(i) != index,
            }"
          >
            <div style="font-size: 30rem">
              {{ dayitem.day }}
              <p style="font-size: 13rem">
                {{ dayitem.tile }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="xian"></div>

    <div
      style="
        display: flex;
        margin-left: 321rem;
        flex-wrap: wrap;
        cursor: pointer;
      "
    >
      <!-- <div class="zhuanti" v-for="item in hyLst">
				<div>
					<div style="display: flex">
						<img src="../assets/zhuanti.png" alt="" />
						<div>
							<div style="margin-top: 18rem; font-size: 20rem">
								{{ item.subject }}
							</div>
							<div style="margin-top: 8rem; font-size: 20rem">
								{{ item.presented }}
							</div>
							<div
								style="
									margin-top: 16rem;
									font-size: 16rem;
									color: #6a6a6a;
								"
							>
								{{ item.personnel_position }}
							</div>
						</div>
					</div>

					<div
						style="
							margin-left: 22rem;
							color: #6a6a6a;
							font-size: 18rem;
						"
					>
						{{ $t("page1.dhsj") }}：{{ item.schedule_time }}
						{{ item.conference_time }}
					</div>
				</div>
			</div> -->

      <div class="zhuanti zhuanti_rel" v-for="item in hyLst" >
        <div>
          <div style="display: flex">
            <img src="../assets/zhuanti.png" alt="" />
            <div>
              <div
                class="shenglve"
                style="margin-top: 18rem; font-size: 20rem; width: 220rem"
              >
                {{ item.subject }}
              </div>
              <div style="margin-top: 8rem; font-size: 20rem">
                {{ item.presented }}
              </div>
              <div
                class="multiSl2"
                style="
                  width: 210rem;
                  margin-top: 20rem;
                  font-size: 16rem;
                  color: #6a6a6a;
                  letter-spacing: 1rem;
                "
              >
                {{ item.personnel_position }}
              </div>
            </div>
          </div>

          <div
            style="
              margin-left: 22rem;
              color: #6a6a6a;
              font-size: 18rem;
              margin-top: 20rem;
              margin-bottom: 20rem;
            "
            class="time_pos"
          >
            <span>
<!--            {{ $t("page1.dhsj") }}：-->
              {{ item.schedule_time }}
            </span>
            <span>
            {{ item.conference_time }}
            </span>
            <!-- {{ item.conference_time }} -->
          </div>
        </div>
      </div>
    </div>
    <!-- 大会日程结束 -->

    <div class="chakan">
      <router-link
        to="home"
        style="font-size: 16rem; margin-left: 32rem; color: #6a6a6a"
      >
        {{ $t("page1.content2_1") }}
      </router-link>
      <img src="../assets/you.png" alt="" />
    </div>

    <!-- 大会出品人开始 -->
    <div class="conter">
      <div
        style="color: #f3f5f9; line-height: 0.7; height: 0; font-size: 81rem"
      >
        Conference Producer
      </div>

      <div class="zhuxi">{{ $t("page1.content3") }}</div>
    </div>

    <div class="conterter">
      <div class="item1" v-for="presidium in res">
        <!-- <div>
            <img src="/assets/ruyuan.png" alt="">
          </div> -->
        <div class="ruyuan">
          <div class="ruyuan" style="height: 210rem">
            <div
              style="
                background-color: #ffff;
                width: 195rem;
                padding-top: 3rem;
                height: 195rem;
                margin-left: 4rem;
                border-radius: 50%;
              "
            >
              <img
                :src="presidium.personnel_img_path"
                alt=""
                style="
                  width: 188rem;
                  height: 190rem;
                  padding-top: 1rem;
                  border-radius: 50%;
                "
              />
            </div>
          </div>
          <p style="color: #355ca1; font-size: 22rem">
            {{ presidium.personnel_name }}
          </p>
          <p
            style="
              color: #355ca1;
              font-size: 16rem;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            "
            class="shenglve"
          >
            {{ presidium.company }}
          </p>
          <p
            style="
              color: #355ca1;
              font-size: 16rem;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            "
            class="shenglve"
          >
            {{ presidium.personnel_position }}
          </p>
          <!--        <p>中国敏捷教育培训企业</p>-->
        </div>
      </div>
    </div>

    <!-- 铂金赞助商开始 -->
    <!-- <div v-show="platinumSponsor && platinumSponsor.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content6") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' +
              platinumSponsors.sponsor_img_path +
              ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="platinumSponsors in platinumSponsor"
        ></div>
      </div>
    </div> -->
    <!-- 铂金赞助商结束 -->

    <!-- 钻石赞助商开始 -->
    <!-- <div v-show="diamondSponsor && diamondSponsor.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content5") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' +
              diamondSponsors.sponsor_img_path +
              ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="diamondSponsors in diamondSponsor"
        ></div>
      </div>
    </div> -->
    <!-- 钻石赞助商结束 -->
    <!-- <router-link to="/contributor" class="chakan">
			<div style="font-size: 16rem; margin-left: 32rem; color: #6a6a6a">
				{{ $t("page1.content2_1") }}
			</div>
			<img src="../assets/you.png" alt="" />
		</router-link> -->

    <!-- 大会出品人结束 -->

    <!-- 白金赞助商开始 -->
    <!-- <div v-show="platinaSponsor && platinaSponsor.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content7") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' +
              platinaSponsors.sponsor_img_path +
              ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="platinaSponsors in platinaSponsor"
        ></div>
      </div>
    </div> -->
    <!-- 白金赞助商结束 -->
    <!-- 独家金融媒体开始 -->
    <!-- <div v-show="financialMedia && financialMedia.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content8") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' +
              financialMedias.sponsor_img_path +
              ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="financialMedias in financialMedia"
        ></div>
      </div>
    </div> -->
    <!-- 独家金融媒体结束 -->
    <!-- 合作伙伴开始 -->
    <!-- <div v-show="partner && partner.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content9") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' + partners.sponsor_img_path + ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="partners in partner"
        ></div>
      </div>
    </div> -->
    <!-- 合作伙伴结束 -->

    <!-- 战略合作伙伴开始 -->
    <!-- <div v-show="strategicPartner && strategicPartner.length > 0">
      <div class="contert">
        <div class="zhuxi0">strategic partner</div>

        <div class="zhuxi">{{ $t("page1.content4") }}</div>
      </div>
      <div class="zhanlue">
        <div
          class="hzhb"
          :style="{
            background:
              'url(' +
              strategicPartners.sponsor_img_path +
              ') center center no-repeat',
            'background-size': contain,
          }"
          v-for="strategicPartners in strategicPartner"
        ></div>
      </div>
    </div> -->
    <!-- 战略合作伙伴结束 -->
    <!-- newCode -->
    <div class="wrap_sponsor" v-show="sponsorList && sponsorList.length > 0">
      <div v-for="(item, index) of sponsorList" :key="index">
        <div class="contert">
          <div class="zhuxi0">{{ item.type_english }}</div>
          <div class="zhuxi">{{ item.sponsor_type }}</div>
        </div>
        <div class="zhanlue">
          <div
            class="hzhb"
            :style="{
              background: 'url(' + val + ') center center no-repeat',
              'background-size': contain,
            }"
            v-for="val in item.urlList"
          ></div>
        </div>
      </div>
    </div>
    <!-- ---------->
    <FooterCom />
    <el-dialog title="" :visible.sync="dialogVisible" width="75%">
      <detail :is_translate="is_translate" :id="selectItem"></detail>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import detail from "@/components/producerDialog.vue";
import FooterCom from "@/components/footerCom.vue";

export default {
  components: {
    detail,
    FooterCom,
  },
  data() {
    return {
      dialogVisible: false,
      selectItem: null,
      is_translate: false,
      days: [], //大会日常天数
      warningList: [],
      // base_img: require("../assets/banner.png"),
      base_img: require("../assets/banner.png"),
      bgc: require("../assets/fistBgc.png"),
      base_subject: "",
      base_subheading: "",
      base_introduce: "",
      base_time: "",
      base_address: "",
      personnel_name: "",
      personnel_position: "",
      presidiums: null, //主席团
      strategicPartner: null, //战略合作伙伴
      diamondSponsor: null, //钻石赞助商
      platinumSponsor: null, //铂金赞助商
      platinaSponsor: null, //白金赞助商
      financialMedia: null, //独家金融媒体
      partner: null, //合作伙伴
      hyLst: null, //大会日常列表
      index: 0, //大会日常默认下标
      sponsorList: [], // 赞助商
      bannerSrc: "",
    };
  },
  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.getRoleList();
    this.sub();
    this.presidium();
    this.strategic();
    this.diamond();
    this.platinum();
    this.platina();
    this.financial();
    this.partn();

    this.conferenceSchedule2();
    // newCode
    this.getActiveSponsor();
    this.getBannerSrc();
  },
  mounted() {},
  watch: {
    is_translate() {
      // this.getRoleList();
      // this.sub();
      // this.presidium();
      // this.strategic();
      // this.diamond();
      // this.platinum();
      // this.platina();
      // this.financial();
      // this.partn();
      // this.conferenceSchedule2();
      // this.getActiveSponsor()
    },
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },

  methods: {
    selectZhuXi(id) {
      this.dialogVisible = true;
      this.selectItem = id;
    },
    overflow(e) {
      if (e.target.innerText == "查看所有") {
        e.target.innerText = "收起";
        let parentBefore = e.target.parentNode.previousSibling;
        parentBefore.style.setProperty("height", "auto");
      } else {
        e.target.innerText = "查看所有";
        let parentBefore = e.target.parentNode.previousSibling;
        parentBefore.style.setProperty("height", "618rem");
      }
    },
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
      this.getRoleList();
      this.sub();
      this.presidium();
      this.strategic();
      this.diamond();
      this.platinum();
      this.platina();
      this.financial();
      this.partn();
      this.conferenceSchedule2();
      this.getActiveSponsor();
    },
    getListByDate(selectedDay) {
      this.$axios(
        "home/page/curriculum?is_translate=" +
          this.is_translate +
          "&schedule_time=" +
          selectedDay,
        "get"
      ).then((res) => {
        this.hyLst = res.data.data;
      });
    },
    // home banner
    getBannerSrc() {
      this.$axios(
          "home/page/getBanner",
          "get"
      ).then((res) => {
        this.bannerSrc = res.data.data.banner;
      });
    },
    dayClick(i) {
      this.index = i;
      let selectedDay = this.days[this.index].schedule_time;
      this.getListByDate(selectedDay);
      // let three = this.times[index].three;
      // for (
      // 	let j = 0;
      // 	index < three.length;
      // 	j++
      // ) {
      // 	this.subjects.push(three[j]);
      // }
    },

    //大会出品人
    getRoleList() {
      this.$axios(
        "/home/page/presented?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          this.res = data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //主题
    sub() {
      this.$axios("/home/page/subject?is_translate=" + this.is_translate, "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);
          // this.res = data
          this.base_subject = data.base_subject;
          this.base_subheading = data.base_subheading;
          this.base_introduce = data.base_introduce;
          this.base_time = data.base_time;
          this.base_address = data.base_address;
          if (data.banner) {
            this.base_img = data.banner;
          }
          this.$forceUpdate();
        })

        .catch((res) => {});
    },
    //主席团
    presidium() {
      this.$axios(
        "/home/page/presidium?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          //console.log(res)
          //this.res = data

          //this.personnel_name = data.personnel_name

          this.presidiums = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //战略合作伙伴
    strategic() {
      this.$axios(
        "/home/page/strategicPartner?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.strategicPartner = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //钻石赞助商
    diamond() {
      this.$axios(
        "/home/page/diamondSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.diamondSponsor = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //铂金赞助商
    platinum() {
      this.$axios(
        "/home/page/platinumSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.platinumSponsor = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //白金赞助商
    platina() {
      this.$axios(
        "/home/page/platinaSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.platinaSponsor = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //独家金融媒体
    financial() {
      this.$axios(
        "/home/page/financialMedia?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.financialMedia = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    //合作伙伴
    partn() {
      this.$axios("/home/page/partner?is_translate=" + this.is_translate, "get")
        .then((res) => {
          this.partner = res.data.data;
          this.$forceUpdate();
        })
        .catch((res) => {});
    },

    conferenceSchedule2() {
      this.$axios("/home/page/days?is_translate=" + this.is_translate, "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);

          this.days = data;
          this.selectedDay = this.days[this.index].schedule_time;
          //this.times
          this.getListByDate(this.selectedDay);
          // this.base_subject = data.base_subject;
          // this.base_time = data.base_time;
          // this.base_address = data.base_address;
        })
        .catch((res) => {});
    },

    // ----------newCode-----------
    async getActiveSponsor() {
      const res = await this.$axios(
        "/home/page/getSponsorList?is_translate=" + this.is_translate,
        "get"
      );
      this.sponsorList = res.data.data.map((item, index) => {
        item.urlList = item.sponsor_img_pathAll.split(",");
        return item;
      });
      console.log(this.sponsorList);
    },
  },
};
</script>
<style lang="less" scoped>
.hzhb {
  margin-bottom: 20rem;
  margin-right: 50rem;
  margin-left: 50rem;
  display: flex;
  justify-content: center;
  width: 266rem;
  height: 166rem;
  align-items: center;
  /* border: 1px solid #ddd; */
  background: url(http://123.56.97.68:8080/group1/M00/00/04/rBIAA2Sb73WAOKFwAAAmGiCMOgw15.webp)
    center center no-repeat;
  background-size: contain;
}

.hzhb:nth-child(4n) {
  margin-right: 0rem;
  margin-left: 0rem;
}

.zhanlue {
  display: flex;
  width: 1365rem;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.tag1 p {
  background-size: cover !important;
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}

.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}

.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}

.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}

.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}

.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}

.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}

.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}

.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}

.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}

.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}

.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}

.active::after {
  color: #355ca1;
  content: "";
  // background-color:aqua;
  position: relative;
  display: block;
  top: 42rem;
  left: 5rem;
  width: 0;
  height: 0;
  border: 20rem solid;
  margin: 0 auto;
  border-color: #355ca1 transparent transparent transparent;
}

.riqi.active {
  color: #355ca1;
  //border: 1rem solid #355ca1;
}

.riqi:hover {
  // border-bottom: 2rem solid #355CA1;
  color: #355ca1;
}

.youqing {
  margin-top: 24rem;
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.first {
  // background: url(../assets/banner.png);
  background-size: cover;
  // height: 686rem;
  min-height: 686rem;
  text-align: center;
  cursor: pointer;
  object-fit: cover;
}

.first_pos {
  width: 100vw;
  //min-height: 686rem;
  height: 300rem;
  position: relative;

  .first_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    //object-fit: cover;
  }
}

.item1:last-child {
  margin-right: 0px;
}

.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}

.chakan {
  width: 166rem;
  height: 52rem;
  line-height: 52rem;
  display: flex;
  margin: 0 auto;
  margin-top: 18rem;
  border: 1rem solid #496caa;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
}

.chakan img {
  width: 20rem;
  height: 15rem;
  margin-top: 20rem;
  margin-left: 18rem;
}

.fex {
  display: flex;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}

.item:hover,
.itemActive {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
}

.item:hover a,
.itemActive a {
  // padding-top: 5rem;
  color: red !important;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.banner {
  width: 1920rem;
  height: 686rem;
  position: absolute; /*设为绝对定位*/
}

.conter {
  font-size: 81rem;
  text-align: center;
  margin-top: 60rem;
  margin-bottom: 74rem;
  //margin-top: 700rem;
}

.zhuxi0 {
  color: rgb(243, 245, 249);
  line-height: 0.7;
  height: 0rem;
  font-size: 81rem;
}

.contert {
  width: 1366rem;
  min-height: 80rem;
  //height: 168rem;
  font-size: 81rem;
  margin: 0 auto;

  // margin-left: 17%;
  padding-top: 44rem;
  text-align: center;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  // text-align: center;
}

.item1 {
  width: 167rem;
  margin-right: 176rem;
  margin-bottom: 30rem;
}

.item1:nth-child(4n) {
  margin-right: 0rem;
}

.item2 {
  width: 188rem;
  margin-right: 350rem;
  margin-bottom: 25rem;
}

.item2:nth-child(3n) {
  margin-right: 0rem;
}

.conterter {
  width: 1293rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  // height: 612rem;
  overflow: hidden;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: center;
}

.day {
  display: flex;
  text-align: center;
  // justify-content: space-around;
  color: #aeaeae;
  cursor: pointer;
}

.xian {
  width: 100%;
  border: 1rem solid #355ca1;
  box-sizing: border-box;
}

.zhuanti {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 30rem;
  margin-left: 15rem;
}

.zhuanti_rel {
  position: relative;
}
.time_pos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 8rem;
  left:15rem;
}


.zhuanti1 {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 15rem;
}

.zhuanti img {
  width: 172rem;
  height: 160rem;
}

.shenglve {
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.multiSl2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiSl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ruyuan {
  background: url(../assets/ruyuan.png) no-repeat;
  width: 204rem;
  // border-radius: 50%;
  background-size: 100%;
  padding-top: 2rem;
}

.foot {
  height: auto;

  .foot_imgs {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
</style>
