import router from "./router";
import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import "./assets/css/global.css";
import "./utils/rem";

import axios from "axios";
import i18n from "./lang"; // internationalization

// axios.defaults.baseURL = "http://192.168.0.106:9527";
// axios.defaults.baseURL="http://121.41.225.75:7102"
//  axios.defaults.baseURL="http://192.168.0.103:8080"
// axios.defaults.baseURL="http://192.168.0.103:9527"

axios.defaults.baseURL = "http://123.56.97.68:9527"; // prod

axios.interceptors.request.use((config) => {
	console.log(config);
	return config;
});
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});
Vue.prototype.$axios = function (url, method, data) {
	if (method.toLowerCase() == "get") {
		return new Promise(function (resolve, reject) {
			axios({ url, method, params: data })
				.then(function (response) {
					resolve(response);
				})
				.catch(function (err) {
					reject(err);
				});
		});
	} else if (method.toLowerCase() == "post") {
		return new Promise(function (resolve, reject) {
			axios({ url, method, data })
				.then(function (response) {
					resolve(response);
				})
				.catch(function (err) {
					reject(err);
				});
		});
	}
};
Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value),
});

new Vue({
	router, //注意这里也要加上
	el: "#app",
	i18n,
	render: (h) => h(App),
});
