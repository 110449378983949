<template>
  <div class="foot foot1">
    <div style="width: 100%; display: flex" class="foot_info">
      <div class="item_info">
        <div style="margin-left: 322rem; font-size: 14rem; margin-top: 83rem">
          {{ $t("bottom.item1.item1") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          "
        >
<!--          {{ $t("bottom.item1.item2") }}-->
          {{ $t("bottom.item1.relationPhone") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          "
        >
<!--          {{ $t("bottom.item1.item3") }}-->
          {{ $t("bottom.item1.relationPhone1") }}
        </div>

<!--        <div
          style="
            margin-left: 322rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("bottom.item1.item4") }}
        </div>-->
      </div>

      <div class="item_info">
        <div style="margin-left: 322rem; font-size: 14rem; margin-top: 83rem">
          {{ $t("ICPMainInfo.license") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          "
        >
          {{ $t("ICPMainInfo.checkTime") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          "
        >
          {{ $t("ICPMainInfo.unitName") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("ICPMainInfo.unitNature") }}
        </div>
      </div>

      <div class="item_info">
        <div style="margin-left: 322rem; font-size: 14rem; margin-top: 83rem">
          {{ $t("ICPServiceInfo.license") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          "
        >
          {{ $t("ICPServiceInfo.domain") }}
        </div>

        <div
          style="
            margin-left: 322rem;
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          "
        >
          {{ $t("ICPServiceInfo.phone") }}
        </div>
      </div>
    </div>

    <div class="foot_imgs">
      <div>
        <div
          style="
            margin-left: 155rem;
            font-size: 14rem;
            margin-top: 24rem;
            margin-bottom: 12rem;
          "
        >
          <img
            src="../assets/si.jpg"
            alt=""
            style="width: 100rem; height: 100rem"
          />
        </div>

        <div
          style="
            margin-left: 170rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("bottom.item8") }}
        </div>
      </div>

      <div>
        <div
          style="
            margin-left: 155rem;
            font-size: 14rem;
            margin-top: 24rem;
            margin-bottom: 12rem;
          "
        >
          <img
            src="../assets/wu.jpg"
            alt=""
            style="width: 100rem; height: 100rem"
          />
        </div>

        <div
          style="
            margin-left: 170rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("bottom.item9") }}
        </div>
      </div>

      <div>
        <div
          style="
            margin-left: 155rem;
            font-size: 14rem;
            margin-top: 24rem;
            margin-bottom: 12rem;
          "
        >
          <img
            src="../assets/erweima.png"
            alt=""
            style="width: 100rem; height: 100rem"
          />
        </div>

        <div
          style="
            margin-left: 170rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("bottom.item4") }}
        </div>
      </div>

      <div>
        <div
          style="
            margin-left: 155rem;
            font-size: 14rem;
            margin-top: 24rem;
            margin-bottom: 12rem;
          "
        >
          <img
            src="../assets/eriweimm.png"
            alt=""
            style="width: 100rem; height: 100rem"
          />
        </div>

        <div
          style="
            margin-left: 170rem;
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          "
        >
          {{ $t("bottom.item5") }}
        </div>
      </div>
    </div>
    <div class="youqing">
      <div>
        {{ $t("bottom.item6") }}:<a
          target="_target"
          href="https://www.iqalliance.org.cn/sy"
          >{{ $t("bottom.item7") }}</a
        >
      </div>
    </div>
    <!-- <div class="youqing" style="margin-top: 12rem">
				<div>{{ $t("bottom.item7") }}</div>
			</div> -->

    <div class="youqing1">
      <div>©2023 TiD Conference 版权所有</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.hzhb {
  margin-bottom: 20rem;
  margin-right: 50rem;
  margin-left: 50rem;
  display: flex;
  justify-content: center;
  width: 266rem;
  height: 166rem;
  align-items: center;
  /* border: 1px solid #ddd; */
  background: url(http://123.56.97.68:8080/group1/M00/00/04/rBIAA2Sb73WAOKFwAAAmGiCMOgw15.webp)
    center center no-repeat;
  background-size: contain;
}
.hzhb:nth-child(4n) {
  margin-right: 0rem;
  margin-left: 0rem;
}
.zhanlue {
  display: flex;
  width: 1365rem;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.tag1 p {
  background-size: cover !important;
}

.tag1:hover div {
  display: flex;
  background: #1f68eb;
}
.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}
.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}
.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}
.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}
.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}
.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}
.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}
.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}
.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}
.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}
.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}
.active::after {
  color: #355ca1;
  content: "";
  // background-color:aqua;
  position: relative;
  display: block;
  top: 42rem;
  left: 5rem;
  width: 0;
  height: 0;
  border: 20rem solid;
  margin: 0 auto;
  border-color: #355ca1 transparent transparent transparent;
}
.riqi.active {
  color: #355ca1;
  //border: 1rem solid #355ca1;
}
.riqi:hover {
  // border-bottom: 2rem solid #355CA1;
  color: #355ca1;
}
.youqing {
  margin-top: 24rem;
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.first {
  // background: url(../assets/banner.png);
  background-size: cover;
  // height: 686rem;
  min-height: 686rem;
  text-align: center;
  cursor: pointer;
  object-fit: cover;
}
.first_pos {
  width: 100vw;
  min-height: 686rem;
  position: relative;
  .first_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}
.item1:last-child {
  margin-right: 0px;
}
.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}

.chakan {
  width: 166rem;
  height: 52rem;
  line-height: 52rem;
  display: flex;
  margin: 0 auto;
  margin-top: 18rem;
  border: 1rem solid #496caa;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
}

.chakan img {
  width: 20rem;
  height: 15rem;
  margin-top: 20rem;
  margin-left: 18rem;
}

.fex {
  display: flex;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}
.item:hover,
.itemActive {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
}
.item:hover a,
.itemActive a {
  // padding-top: 5rem;
  color: red !important;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.banner {
  width: 1920rem;
  height: 686rem;
  position: absolute; /*设为绝对定位*/
}

.conter {
  font-size: 81rem;
  text-align: center;
  margin-top: 60rem;
  margin-bottom: 74rem;
  //margin-top: 700rem;
}
.zhuxi0 {
  color: rgb(243, 245, 249);
  line-height: 0.7;
  height: 0rem;
  font-size: 81rem;
}
.contert {
  width: 1366rem;
  height: 168rem;
  font-size: 81rem;
  margin: 0 auto;

  // margin-left: 17%;
  padding-top: 90rem;
  text-align: center;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  // text-align: center;
}
.item1 {
  width: 167rem;
  margin-right: 176rem;
  margin-bottom: 30rem;
}
.item1:nth-child(4n) {
  margin-right: 0rem;
}
.item2 {
  width: 188rem;
  margin-right: 350rem;
  margin-bottom: 25rem;
}
.item2:nth-child(3n) {
  margin-right: 0rem;
}
.conterter {
  width: 1293rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  // height: 612rem;
  overflow: hidden;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: center;
}

.day {
  display: flex;
  text-align: center;
  // justify-content: space-around;
  color: #aeaeae;
  cursor: pointer;
}

.xian {
  width: 100%;
  border: 1rem solid #355ca1;
  box-sizing: border-box;
}

.zhuanti {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 30rem;
  margin-left: 15rem;
}

.zhuanti1 {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 15rem;
}

.zhuanti img {
  width: 172rem;
  height: 160rem;
}
.shenglve {
  /*1. 先强制一行内显示文本*/
  white-space: nowrap;
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
.multiSl2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiSl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ruyuan {
  background: url(../assets/ruyuan.png) no-repeat;
  width: 204rem;
  // border-radius: 50%;
  background-size: 100%;
  padding-top: 2rem;
}

.foot {
  width: 100% !important;
  height: auto !important;
  background-color: #f2f2f2 !important;
  .foot_imgs {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
</style>
