<template>
	<div>
		<div style="display: flex; margin-top: 30rem">
			<div class="ruyuan center">
				<div class="ruyuan1 center">
					<img :src="personnel_img_path" alt="" class="tupian" />
				</div>
			</div>
			<div
				style="flex: 0 1 auto; width: 1024rem; margin-left: 39rem"
				class="clearfix descTitle"
			>
				<div style="width: 100%">
					<div
						style="
							display: inline-block;
						"
					>
						<span style="display: inline-block; width: 90rem;text-align: justify;
							text-align-last: justify;">{{
						$t("producerDialog.item1")
					}}</span
					>:&nbsp;&nbsp;<span
							style="font-size: 18rem; color: black"
							>{{ personnel_name }}</span
						>
					</div>
				</div>
				<!-- <div style="margin-top: 20rem; display: flex">
					<span style="display: inline-block; width: 90rem">{{
						$t("producerDialog.item2")
					}}</span
					>:&nbsp;&nbsp;<span style="font-size: 18rem; flex: 1; color: black">{{
						company
					}}</span>
				</div> -->

				<div style="margin-top: 20rem; display: flex">
					<span style="display: inline-block; width: 90rem">{{
						$t("producerDialog.item3")
					}}</span
					>:&nbsp;&nbsp;<span style="font-size: 18rem; flex: 1; color: black">
						{{company}}
						{{
						personnel_position
					}}</span>
				</div>

				<div style="display: flex; margin-top: 20rem">
					<span
						style="
							display: inline-block;
							width: 90rem;
									text-align: justify;
							text-align-last: justify;
						"
						>{{ $t("producerDialog.item4") }}</span
					>:&nbsp;&nbsp;
					<span style="font-size: 18rem; flex: 1; color: black">{{
						personnel_introduce
					}}</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			subject: "",
			conference_address: "",
			conference_time: "",
			personnel_position: "",
			course_summary: "",
			case_come: "",
			speech_title: "",
			personnel_name: "",
			company: "",
			personnel_position: "",
			personnel_introduce: "",
			personnel_img_path: "",
			data: "",
		};
	},
	props: {
		id: 0,
		is_translate: false,
	},
	computed: {
		parentId: function () {
			return this.id;
		},
	},

	watch: {
		parentId(newName, oldName) {
			this.scheduleDetail(newName);
		},
	},
	created() {
		if (sessionStorage.getItem("language") == "en") {
			this.is_translate = true;
		}
		this.scheduleDetail(this.id);
	},
	methods: {
		//主题
		scheduleDetail(id) {
			this.$axios(
				"/detail/page/personnelDetail?is_translate=" +
					this.is_translate +
					"&id=" +
					id,
				"get"
			)
				.then((res) => {
					let data = res.data.data;
					console.log(res);
					this.data = data;
					this.personnel_name = data.personnel_name;
					this.company = data.company;
					this.personnel_position = data.personnel_position;
					this.personnel_introduce = data.personnel_introduce;
					this.personnel_img_path = data.personnel_img_path;
				})
				.catch((res) => {});
		},
	},
};
</script>
<style lang="less" scoped>
.clearfix:before,
.clearfix:after {
	content: "   ";
	display: table;
}
.descTitle {
	font-size: 20rem;
	color: #355ca1;
}
.float {
	float: left;
	width: 50%;
	line-height: 0;
}
.desc {
	font-weight: normal;
	color: #6a6a6a;
	font-size: 16rem;
}
.tupian {
	width: 220rem;
	height: 220rem;
	border-radius: 50%;
}
.yingwen {
	text-align: center;
	position: relative;
}

.header {
	width: 1920rem;
	height: 115rem;
	// background-color: pink;
	display: flex;
}
.imger {
	width: 116rem;
	height: 79rem;
	margin-left: 321rem;
	margin-top: 18rem;
}
.header_png {
	width: 757rem;
	justify-content: space-around;
	display: flex;
	margin-top: 45rem;
	font-size: 20rem;
}
.item:hover {
	// padding-top: 5rem;
	border-bottom: 2px solid red;
	color: red;
}
.juxingimg {
	width: 139rem;
	height: 41rem;
	margin-left: 88rem;
	margin-top: 37rem;
}
.biankuang {
	width: 139rem;
	height: 41rem;
	margin-top: 37rem;
	margin-left: 131rem;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
	display: flex;
}
.yuyanimg {
	margin-left: 8rem;
	width: 25rem;
	height: 25rem;
	margin-top: 7rem;
}
.input {
	width: 139rem;
	height: 41rem;
	background-color: #efefef;
	border-radius: 25rem 25rem 25rem 25rem;
	position: absolute;
	right: 403rem;
	top: 32rem;
}
.input1 {
	width: 25rem;
	height: 25rem;

	margin-top: 9rem;
	margin-left: 22rem;
}
.gianbi {
	font-size: 18rem;
	margin-top: -25rem;
	margin-left: 26rem;
	color: #496caa;
}
.ruyuan {
	width: 240rem;
	height: 240rem;
	// border-radius: 50%;
	background: url(../assets/ruyuan.png) no-repeat;
	background-size: 100%;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.ruyuan1 {
	background: #fff;
	border-radius: 50%;
	height: 227rem;
	width: 227rem;
}
</style>
