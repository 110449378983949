<template>
	<div>
		<!-- 面包屑 -->
		<!-- <el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/home' }"
				>账户管理</el-breadcrumb-item
			>
			<el-breadcrumb-item><a href="/">账户列表</a></el-breadcrumb-item>
		</el-breadcrumb> -->

		

		<el-card align="left">
			<!-- 搜索框 -->

			<el-row :gutter="20">
				<el-col :span="7"
					><el-input
						placeholder="请输入内容"
						v-model="queryInfo.keyword"
						clearable
						@clear="getuserlist"
					>
						<el-button
							@click="searchbtn"
							slot="append"
							icon="el-icon-search"
						></el-button> </el-input
				></el-col>
				<el-col :span="4">
					<el-button type="primary" @click="addUserVisible = true"
						>添加账户</el-button
					>
				</el-col>
			</el-row>

			<el-table
				:data="userlist"
				border
				style="width: 100%"
				:header-cell-style="{ background: '#eaedf1',color:'black'}"
			
				
			>
				<el-table-column
					prop="id"
					label="编号"
					width="327"
					align="center"
					
				>
				</el-table-column>
				<el-table-column prop="name" label="真实姓名" align="center">
				</el-table-column>
				<el-table-column prop="phoneNumber" label="手机号" align="center">
				</el-table-column>

				<el-table-column prop="sex" label="性别" align="center">
				</el-table-column>

                	<el-table-column prop="siteId" label="场地" align="center">
				</el-table-column>

                	<el-table-column prop="roleid" label="角色" align="center" >
				</el-table-column>

	<el-table-column prop="username" label="登录账号" align="center" >
				</el-table-column>

                	<el-table-column prop="password" label="用户密码" align="center">
				</el-table-column>

                	<el-table-column prop="seate" label="禁用" align="center">
				</el-table-column>



				<el-table-column prop="" label="操作" width="329" align="center">
					<template slot-scope="scope">
						<el-button
							type="primary"
							icon="el-icon-edit"
							size="mini"
							@click="editUser(scope.row)"
						></el-button>

						<el-button
							type="danger"
							icon="el-icon-delete"
							size="mini"
							@click="remove(scope.row)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				@size-change="SizeChange"
				@current-change="CurrentChange"
				:current-page="queryInfo.pageNum"
				:page-sizes="[10, 20, 3, 4]"
				:page-size="queryInfo.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</el-card>

		<!-- 添加信息 -->

		<el-dialog
			title="添加账户信息"
			:visible.sync="addUserVisible"
			width="50%"
		>
			<el-form
				:model="addForm"
				:rules="addFormRules"
				ref="addFormRef"
				label-width="100rem"
			>
				<el-form-item label="登录账号：" prop="username">
					<el-input v-model="addForm.username"></el-input>
				</el-form-item>

				<el-form-item label="登陆密码：" prop="password">
					<el-input v-model="addForm.password"></el-input>
				</el-form-item>

				<el-form-item label="角色名字：" prop="roleid">
					<el-select
						v-model="addForm.roleid"
						placeholder="请选择角色"
						clearable
						style="width: 100%"
					>
						<el-option
							v-for="(item, index) in roleList"
							:key="index"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>

				<el-form-item label="场地名字：" prop="siteId">
					<el-input v-model="addForm.siteId"></el-input>
				</el-form-item>

				<el-form-item label="真实名：" prop="name">
					<el-input v-model="addForm.name"></el-input>
				</el-form-item>

				<el-form-item label="手机号：" prop="phoneNumber">
					<el-input v-model="addForm.phoneNumber"></el-input>
				</el-form-item>

				<el-form-item label="性别：" prop="sex">
					<el-input v-model="addForm.sex"></el-input>
				</el-form-item>

				<el-form-item label="状态：" prop="seate">
					<el-input v-model="addForm. seate"></el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="addUserVisible = false">取 消</el-button>
				<el-button type="primary" @click="adduser">确 定</el-button>
			</span>
		</el-dialog>

		<!-- //编辑 -->

		<el-dialog title="编辑信息" :visible.sync="addVisible" width="50%">
			<el-form
				:model="editForm"
				:rules="addFormRules"
				ref="addFormRef"
				label-width="100rem"
			>
				<el-form-item label="登录账号：" prop="username">
					<el-input v-model="editForm.username"></el-input>
				</el-form-item>

				<el-form-item label="登陆密码：" prop="password">
					<el-input v-model="editForm.password"></el-input>
				</el-form-item>

				<el-form-item label="角色名字：" prop="roleid">
					<el-select
						v-model="editForm.roleid"
						placeholder="请选择角色"
						clearable
						style="width: 100%"
					>
						<el-option
							v-for="(item, index) in roleList"
							:key="index"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>

				<el-form-item label="场地名字：" prop="siteId">
					<el-input v-model="editForm.siteId"></el-input>
				</el-form-item>

				<el-form-item label="真实名：" prop="name">
					<el-input v-model="editForm.name"></el-input>
				</el-form-item>

				<el-form-item label="手机号：" prop="phoneNumber">
					<el-input v-model="editForm.phoneNumber"></el-input>
				</el-form-item>

				<el-form-item label="性别：" prop="sex">
					<el-input v-model="editForm.sex"></el-input>
				</el-form-item>

				<el-form-item label="状态：" prop="seate">
					<el-input v-model="editForm.seate"></el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="addVisible = false">取 消</el-button>
				<el-button type="primary" @click="editUserupdate"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			roleList: [],
			queryInfo: {
				keyword: "",
				pageSize: 10,
				pageNum: 1,
			},
			userlist: [],
			//当前数据总数
			total: 0,
			//添加显示影藏
			addUserVisible: false,
			//编辑
			addVisible: false,
			//表单数据
			addForm: {
				username: "",
				name: "",
				roleid: "",
				password: "",
				sex: "",

				phoneNumber: "",
				siteId: "",
			},
			editForm: {
				username: "",
				name: "",
				roleid: "",
				password: "",
				sex: "",
				phoneNumber: "",
				siteId: "",
			},
			//添加表单的验证规则
			addFormRules: {
				username: [
					{
						required: true,
						message: "请输入用户名字",
						trigger: "blur",
					},
					{
						min: 3,
						max: 10,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
				nickName: [
					{
						required: true,
						message: "请输入真实名字",
						trigger: "blur",
					},
					{
						min: 6,
						max: 15,
						message: "用户长度需要在6到15个字符之间",
						trigger: "blur",
					},
				],
				roleid: [
					{
						required: true,
						message: "请输入角色名字",
						trigger: "blur",
					},
					{
						min: 1,
						max: 3,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
				password: [
					{
						required: true,
						message: "请输入角色名字",
						trigger: "blur",
					},
					{
						min: 1,
						max: 3,
						message: "用户长度需要在3到10个字符之间",
						trigger: "blur",
					},
				],
			},
			//存储获取到的额信息
			editUserpams: {},
		};
	},
	methods: {
		//获取角色列表
		getRoleList() {
			this.$axios("/user/CdRole/listAll", "get")
				.then((res) => {
					console.log(res);
					this.roleList = res.data.data;
				})
				.catch((res) => {});
		},
		getuserlist() {
			this.$axios("/account/Account/list", "get", this.queryInfo)
				.then((res) => {
					console.log(res);
					if (res.data.code !== 200) this.$message.error("失败");
					this.total = res.data.data.total;
					this.userlist = res.data.data.list;
					console.log(this.userlist);
				})
				.catch((err) => {
					console.log(err);
				});
		},

		//当每页数据发生变化时候触发
		SizeChange(newsize) {
			this.queryInfo.pageSize = newsize;
			this.getuserlist();
		},
		//当前页码发生变化时候触发
		CurrentChange(newNum) {
			this.queryInfo.pageNum = newNum;
			this.getuserlist();
		},
		//添加用户
		adduser() {
			this.$axios("/account/Account/create", "post", this.addForm)
				.then((res) => {
					console.log(res);
					this.addUserVisible = !this.addUserVisible;
					this.getuserlist();
				})

				.catch((err) => {
					console.log(err);
				});
		},
		// 编辑信息
		editUser(row) {
			this.editForm = row;
			this.addVisible = !this.addVisible;
		},
		editUserupdate() {
			// debugger
			this.$axios(
				"/account/Account/update/" + this.editForm.id,
				"post",
				this.editForm
			)
				.then((res) => {
					// console.log(res);
					this.addVisible = !this.addVisible;
					this.getuserlist();
				})
				.catch((err) => {
					console.log(err);
				});
		},

		//删除
		remove(row) {
			//    const that = this;
			this.$confirm("是否删除该角色", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.$axios(
						"/account/Account/delete/" + row.id,
						"post"
						// this.editForm,
					).then(() => {
						this.getuserlist();
					});
				})
				.catch(() => {});
		},
		//查询
		searchbtn() {
			this.$axios("/account/Account/list", "get", this.queryInfo).then(
				(res) => {
					console.log(res);
					if (res.data.code !== 200) this.$message.error("失败");
					this.userlist = res.data.data.list;
					console.log(this.userlist);
				}
			);
		},
	},

	created() {
		this.getRoleList();
		this.getuserlist();
	},
};
</script>
<style lang="">

</style>
