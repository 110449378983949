<template>
	<div>
		<div class="header">
			<div><img src="../assets/toubu.png" alt="" class="imger" /></div>

			<div class="header_png">
				<div class="item" >首页</div>
				<div class="item" >大会日程</div>
				<div class="item">演讲征集</div>
				<div class="item">历届回顾</div>
			</div>

			<div>
				<img src="../assets/juxing.png" alt="" class="juxingimg" />
				<div
					style="
						position: absolute;
						height: 25rem;
						z-indent: 2;
						top: 45rem;
						right: 640rem;
						color: white;
					"
				>
					文字
				</div>
				<div>
					<img
						src="../assets/menpiao.png"
						alt=""
						style="
							position: absolute;
							width: 28rem;
							height: 20rem;
							z-indent: 2;
							top: 48rem;
							right: 603rem;
							color: white;
						"
					/>
				</div>
			</div>

			<div class="biankuang">
				<div
					style="
						font-size: 14rem;
						margin-left: 21rem;
						margin-top: 8rem;
						color: #242424;
					"
				>
					English
				</div>
				<img src="../assets/yuyan.png" alt="" class="yuyanimg" />
			</div>
		</div>

		<div>
			<img src="../assets/huaban.png" alt="" class="banner" />

			<div
				style="
					width: inherit;

					font-size: 130rem;
					color: #ffffff;
					left: 30%;

					position: absolute; /*设为绝对定位*/
				"
			>
				TiD质量竞争力大会
			</div>

			<div
				style="
					width: inherit;

					font-size: 56rem;
					color: #ffffff;
					top: 292rem;
					left: 30%;

					position: absolute; /*设为绝对定位*/
				"
			>
				日程安排
			</div>
		</div>

		<div
			style="
				width: inherit;

				font-size: 56rem;
				color: #ffffff;
				top: 387rem;
				left: 30%;

				position: absolute; /*设为绝对定位*/
			"
		>
			2023年9月.中国.北京
		</div>

		<div
			style="
				width: inherit;

				font-size: 25rem;
				color: #ffffff;
				top: 515rem;
				left: 30%;
				line-height: 45rem;
				position: absolute; /*设为绝对定位*/
			"
		></div>

		<div
			style="
				width: 68rem;
				height: 68rem;
				color: #ffffff;
				top: 395rem;
				left: 95%;
				line-height: 45rem;
				position: absolute; /*设为绝对定位*/
			"
		>
			<img src="../assets/onw.png" alt="" />
			<img src="../assets/tow.png" alt="" />
			<img
				src="../assets/sow.png"
				alt=""
				style="width: 68rem; height: 68rem"
			/>
			<img src="../assets/fow.png" alt="" />
			<img
				src="../assets/sov.png"
				alt=""
				style="width: 68rem; height: 68rem"
			/>
		</div>

		<div class="conter">
			<div style="opacity: 0.1">The presidium of general assembly</div>

			<div class="zhuxi">大会日程</div>
		</div>

		<div class="day">
			<div class="riqi" style="margin-left: 475rem">
				<div style="font-size: 30rem">
					Day1
					<p style="font-size: 13rem">2023 / 09 / 05</p>
				</div>
			</div>
			<div class="riqi" style="margin-left: 121rem">
				<div style="font-size: 30rem">
					Day2
					<p style="font-size: 13rem">2023 / 09 / 09</p>
				</div>
			</div>
			<div class="riqi" style="margin-left: 108rem">
				<div style="font-size: 30rem">
					Day3
					<p style="font-size: 13rem">2023 / 09 / 15</p>
				</div>
			</div>
		</div>
		<div class="xian"></div>

	<div class="chengcehng">
		<div class="fang"></div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">低代码</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：蓝晓斌</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">云原生微服务</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：王晔惊</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>

		
	</div>
		<div class="chang">
			<div class="jiegou">

			

			</div>
			<div class="jiegou">

				<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
		</div>


				<div class="chang1">
			<div class="jiegou">

			

			</div>
			<div class="jiegou">

				<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
		</div>

			<div class="chengcehng">
		<div class="fang"></div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">低代码</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：蓝晓斌</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">云原生微服务</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：王晔惊</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>
		<div class="fang">
			<div style="font-size: 20rem;color:#ffff;margin-top: 13rem;margin-left: 15rem;">中台落地</div><br>
			<div  style="font-size: 16rem;color:#ffff;margin-left: 15rem;">出品人：马若飞</div>
		</div>

		
	</div>

	<div class="chang">
			<div class="jiegou">

			

			</div>
			<div class="jiegou">

				<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
		</div>

		<div class="chang1">
			<div class="jiegou">

			

			</div>
			<div class="jiegou">

				<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
			<div class="jiegou">
					<div style="font-size: 18rem;color:#6A6A6A;margin-left: 15rem;margin-top: 23rem;">以需求结构化 为中心的Web 研发体系</div><br>
				<div style="font-size: 20rem;color:#496CAA;margin-left: 15rem;">侯振宇</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">快手</div><br>
				<div style="font-size: 16rem;color:#496CAA;margin-left: 15rem;">商业化通用技术 组负责人</div>
			</div>
		</div>


		<div class="foot">
			<div style="width: 100%; display: flex">
				<div>
					<div
						style="
							margin-left: 322rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						
						{{$t("bottom.item1.item1")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item1.item2")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item1.item3")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item1.item4")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						{{$t("bottom.item2.item1")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item2.item2")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item2.item3")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item2.item4")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item2.item5")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						{{$t("bottom.item3.item1")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item3.item2")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item3.item3")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item3.item4")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item3.item5")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						<img src="../assets/erweima.png" alt="" style="width: 100rem;
    height: 100rem;" />
					</div>

					<div
						style="
							margin-left: 170rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item4")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						<img src="../assets/erweima.png" alt="" style="width: 100rem;
    height: 100rem;"/>
					</div>

					<div
						style="
							margin-left: 170rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item5")}}
					</div>
				</div>
			</div>
			<div class="youqing">
				<div>{{ $t("bottom.item6") }}:<a target="_target" href="https://www.iqalliance.org.cn/sy">{{ $t("bottom.item7") }}</a></div>
			</div>

			<div class="youqing1">
				<div>©2023 TiD Conference 版权所有</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
		
		};
	},
	created() {
	
		if(sessionStorage.getItem("language")== 'en'){
			this.is_translate = true;
		}
		
	},
	methods: {
	
	},
};
</script>
<style lang="less" scoped>

.foot {
	width: 100%;
	height: 360rem;
	background-color: #f2f2f2;
	margin-top: 90rem;
	display: flex;
}
.chakan {
	width: 166rem;
	height: 52rem;
	line-height: 52rem;
	display: flex;
	margin: 0 auto;
	margin-top: 18rem;
	border: 1rem solid #496caa;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
}
.chakan img {
	width: 20rem;
	height: 15rem;
	margin-top: 20rem;
	margin-left: 18rem;
	
}

.chengcehng{
	display: flex;
	width: 1393rem;
	height: 88rem;
	margin: 0 auto;
	margin-top: 46rem;
	
	
}
.chang{
	display: flex;
	width: 1390rem;
	height: 260rem;
	margin: 0 auto;
	background-color: #F0F3F8;
	//border: 1rem solid #355ca1;
}
.chang1{
	display: flex;
	width: 1390rem;
	height: 260rem;
	margin: 0 auto;
	background-color: #F8FAFC;
	//border: 1rem solid #355ca1;
}
.fang{
	width: 141rem;
	border: 1rem solid #FFFFFF;
	background-color:#355CA1;
}
.jiegou{
	width: 141rem;
	height: 260rem;
	border: 1rem solid #ffff;
	//background-color:red;
	
}
.riqi {
	width: 243rem;
	height: 88rem;
	border: 1rem solid #355ca1;
}

.fex {
	display: flex;
}
.header {
	width: 1920rem;
	height: 115rem;
	// background-color: pink;
	display: flex;
}
.imger {
	width: 116rem;
	height: 79rem;
	margin-left: 321rem;
	margin-top: 18rem;
}
.header_png {
	width: 757rem;
	justify-content: space-around;
	display: flex;
	margin-top: 45rem;
	font-size: 20rem;
}
.item:hover {
	// padding-top: 5rem;
	border-bottom: 2px solid red;
	color: red;
}
.juxingimg {
	width: 139rem;
	height: 41rem;
	margin-left: 88rem;
	margin-top: 37rem;
}
.biankuang {
	width: 139rem;
	height: 41rem;
	margin-top: 37rem;
	margin-left: 131rem;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
	display: flex;
}
.yuyanimg {
	margin-left: 8rem;
	width: 25rem;
	height: 25rem;
	margin-top: 7rem;
}
.banner {
	width: 1920rem;
	height: 686rem;
	position: absolute; /*设为绝对定位*/
}
.conter {
	width: 1366rem;
	height: 168rem;
	font-size: 81rem;

	margin-top: 700rem;
	margin-left: 17%;
}

.contert {
	width: 1366rem;
	height: 168rem;
	font-size: 81rem;

	margin-left: 17%;
}
.zhuxi {
	color: #355ca1;
	font-size: 50rem;
	margin-top: -86rem;
	margin-left: 35%;
}

.day {
	display: flex;
	text-align: center;
	// justify-content: space-around;
	color: #aeaeae;
}
.day div:hover {
	// border-bottom: 2rem solid #355CA1;
	color: #355ca1;
}
.xian {
	width: 100%;
	border: 1rem solid #355ca1;
	box-sizing: border-box;
}
</style>
