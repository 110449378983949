
<template>
	<div>
		<div class="header"
		
			style="position: fixed; left: 0; top: 0; background: #fff">
			<div><img src="../assets/toubu.png" alt="" class="imger" /></div>

			<div class="header_png">
				<div class="item" ><router-link to="/login" class="hove">{{$t("navbar.item1")}}</router-link></div>
				<div class="item" ><router-link to="/home" class="hove">{{$t("navbar.item2")}}</router-link></div>
				<div class="item" > <router-link to="/lecture" class="hove">{{$t("navbar.item3")}}</router-link></div>
				<div class="item" >{{$t("navbar.item4")}}</div>
			</div>

			<div style="position: relative;">
				<img src="../assets/juxing.png" alt="" class="juxingimg" />
				<router-link to="/ticket"
					style="
						position: absolute;
						height: 25rem;
						z-indent: 2;
						top: 45rem;
						right: 70rem;
						color: white;
						font-size: 16rem;
					"
				>
				{{$t("navbar.item5")}}
				</router-link>
				<div>
					<img
						src="../assets/menpiao.png"
						alt=""
						style="
							position: absolute;
							width: 28rem;
							height: 20rem;
							z-indent: 2;
							top: 48rem;
							right: 20rem;
							color: white;
						"
					/>
				</div>
			</div>

			<a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
				<div 
					style="
						margin-top: 8rem;
						font-size: 18rem;
						margin-left: 21rem;
width: 61rem;
						color: #242424;
					"
				>
					English
				</div>
				<img src="../assets/yuyan.png" alt="" class="yuyanimg" />
			</a>
			<a class="biankuang" v-else  @click="changeLang(false)">
				<div
					style="
						margin-top: 8rem;
						font-size: 18rem;
						margin-left: 21rem;
width: 61rem;
						color: #242424;
					"
				>
				China
				</div>
				<img src="../assets/yuyan.png" alt="" class="yuyanimg" />
			</a>
		</div>
		<div>
			<div class="jing" style="    margin-top: 115rem;">
				<!-- <div class="dahui">
					<div>{{$t("contr.tit1")}}</div>
				</div>
				<div class="zhenji">{{$t("contr.tit1_1")}}</div>

				<div class="toum">
					<div class="shidai">{{$t("contr.tit2")}}</div>
				</div> -->
			</div>
		</div>

			<div
				style="
					width: 68rem;
					color: rgb(255, 255, 255);
					line-height: 45rem;
					position: absolute;
					top: 345rem;
					right: 41rem;
					position: fixed;
				"
			>
				<router-link
				to="/contr"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>
					<!-- <img src="../assets/onw.png" alt="" /> -->
					<div>{{$t("right.item8")}}</div></router-link
				>
<router-link
					v-if="false"
				to="/news"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>
					<!-- <img src="../assets/onw.png" alt="" /> -->
					<div>{{$t("right.item1")}}</div></router-link
				>
				<router-link
					to="/contributor"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>
					<!-- <img src="../assets/tow.png" alt="" /> -->

					<div>{{$t("right.item2")}}</div></router-link
				>
				<router-link
					to="/sponsor"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>
					<!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
					<div>{{$t("right.item3")}}</div></router-link
				>
				<router-link
					to="/ticket"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>
					<!-- <img src="../assets/fow.png" alt="" /> -->

					<div>{{$t("right.item4")}}</div></router-link
				>
				<router-link
					to="/lecture"
					class="tag1"
					style="position: relative; display: block"
				>
					<p style="width: 68rem; height: 68rem"></p>

					<div>{{$t("right.item5")}}</div></router-link
				>
			</div>
        	<div class="conter">
			<div style="color: rgb(243, 245, 249);text-align: center;">news and trends</div>

			<div class="zhuxi">{{$t("right.item6")}}</div>
		</div>
<div class="dangqian">{{$t("right.item7")}} {{newsdetail.title}}</div>
<div class="xiamian">
    <div class="biao">{{newsdetail.title}}</div>
      <div class="shijian">{{newsdetail.publish_time}}</div>
      <div class="zhi" v-html="newsdetail.content"></div>
      <!-- <div><img :src="newsdetail.cover" alt="" class="zhong" ></div> -->
     

       <!-- <div><img src="../assets/tu.png" alt="" class="zhong" ></div> -->
      
</div>

		
	<div class="foot">
			<div style="width: 100%; display: flex">
				<div>
					<div
						style="
							margin-left: 322rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						
						{{$t("bottom.item1.item1")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item1.item2")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item1.item3")}}
					</div>

					<div
						style="
							margin-left: 322rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item1.item4")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						{{$t("bottom.item2.item1")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item2.item2")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item2.item3")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item2.item4")}}
					</div>

					<div
						style="
							margin-left: 157rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item2.item5")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						{{$t("bottom.item3.item1")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 8rem;
						"
					>
						{{$t("bottom.item3.item2")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 12rem;
							color: #706f6f;
							margin-top: 9rem;
						"
					>
						{{$t("bottom.item3.item3")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item3.item4")}}
					</div>

					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item3.item5")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						<img src="../assets/erweima.png" alt="" />
					</div>

					<div
						style="
							margin-left: 170rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item4")}}
					</div>
				</div>

				<div>
					<div
						style="
							margin-left: 155rem;
							font-size: 14rem;
							margin-top: 83rem;
						"
					>
						<img src="../assets/erweima.png" alt="" />
					</div>

					<div
						style="
							margin-left: 170rem;
							font-size: 14rem;
							color: #706f6f;
							margin-top: 12rem;
						"
					>
						{{$t("bottom.item5")}}
					</div>
				</div>
			</div>
			<div class="youqing">
				<div>{{ $t("bottom.item6") }}:<a target="_target" href="https://www.iqalliance.org.cn/sy">{{ $t("bottom.item7") }}</a></div>
			</div>

			<div class="youqing1">
				<div>©2023 TiD Conference 版权所有</div>
			</div>
		</div>


	
	</div>
</template>

<script>
import detail from '@/components/producerDialog.vue';
export default {
	components:{
		detail
	},
	data() {
		return {
			newsdetail: null, //主席团
			dialogVisible: false,
			selectItem:null,
			is_translate: false,
            id:"",
						
		};
		
	},
	created() {
        this.id = this.$route.query.id;
		if(sessionStorage.getItem("language")== 'en'){
			this.is_translate = true;
		}
	    this.presidium();
        this.getJourn();
	},
	watch:{
		is_translate(){
			this.presidium();
            this.getJourn();
		},
		
	},
	computed: {
		lang: {
			get() {
				return sessionStorage.getItem("language");
			},
			set(lang) {
				this.$i18n.locale = lang;
				sessionStorage.setItem("language", lang);
			},
		},
	},
	
	methods: {

    	getJourn() {
			this.$axios("/journ/page/getJourn?is_translate="+this.is_translate+"&id="+this.id, "get")
				.then((res) => {
					let data = res.data.data;
					this.newsdetail = data;
					console.log(res);
				})
				.catch((res) => {});
		},
		changeLang(is_translate){
			this.is_translate = is_translate;
			if(is_translate){
				this.lang = 'en';
			}else{
				this.lang = 'zh';
			}
			
		},
		selectHy(id){
			this.dialogVisible = true;
			this.selectItem = id;
		},
		// pClick(id){
		// 	this.$router.push({name:'producer',params:{id:id}})
		// },
			//主席团
		presidium() {
			this.$axios("/home/page/presidium?is_translate="+this.is_translate, "get")
				.then((res) => {
					let data = res.data.data;
				
					this.presidiums = res.data.data;
				})
				.catch((res) => {});
		}

	}
};
</script>
<style lang="less" scoped>
.jie{
      font-size: 16rem;
    padding-top: 45rem;
    margin-left: 35rem; 
}
.zhong{
    width: 1080rem;
    height: 507rem;
   padding-top: 21rem;
   padding-left: 100rem;
    }
.zhi{
    font-size: 16rem;
    padding-top: 45rem;
    margin-left: 35rem;
}
.xia{
     font-size: 16rem;
    padding-top: 45rem;
    margin-left: 35rem;
}
.shijian{
    text-align: center;
    font-size: 16rem;
    padding-top: 11rem;
}
.biao{
    font-size: 22rem;
   text-align: center;
  padding-top: 34rem;
}
.xiamian{
    width: 1280rem;
    // height: 1432rem;
    background-color: #F4F7FA;
    margin: 0 auto;
    margin-top: 37rem;
}
.dangqian{
    font-size: 16rem;
text-align: center;
}
.tiao{
    width: 1279rem;

    margin: 0 auto;
    margin-top: 32rem;

	border: 1rem solid #EBEBEB;
	box-sizing: border-box;
}



.xian{
   
    padding-top: 19rem;
    font-size: 16rem;}
.lae{
    padding-top: 15rem;
    font-size: 20rem;
}
.jicai{
    width: 151rem;
    height: 39rem;
    font-size: 20rem;
    color: #ffffff;
    text-align: center;
    line-height: 39rem;
    background-color:#496CAA ;
   

}
.now{
    width: 352rem;
    height: 220rem;
}
.tag1 p{
	background-size: cover !important;
}

.tag1:hover div{
    display: block;
    background: #1f68eb;
}
.tag1:nth-child(1):hover p{
    background: url(../assets/onw1.png);
}
.tag1:nth-child(2):hover p{
    background: url(../assets/tow1.png);
}
.tag1:nth-child(3):hover p{
    background: url(../assets/sow1.png);
}
.tag1:nth-child(4):hover p{
    background: url(../assets/fow1.png);
}
.tag1:nth-child(5):hover p{
    background: url(../assets/sov1.png);
}
.tag1:nth-child(1) p{
    background: url(../assets/onw.png);
    background-size: cover;
}
.tag1:nth-child(2) p{
    background: url(../assets/tow.png);
    background-size: cover;
}
.tag1:nth-child(3) p{
    background: url(../assets/sow.png);
    background-size: cover;
}
.tag1:nth-child(4) p{
    background: url(../assets/fow.png);
    background-size: cover;
}
.tag1:nth-child(5) p{
    background: url(../assets/sov.png);
    background-size: cover;
}
.tag1 div{
  display: none;
  color: white;
  width: 220rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 6rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: left;
  padding: 11rem 22rem;
  box-sizing: border-box;
  font-size: 16rem;


}
.youqing {
	font-size: 12rem;
	display: flex;
	margin-left: 324rem;
	color: #706f6f;
	
}
.youqing1{
		font-size: 12rem;
	display: flex;
	margin-left: 869rem;
	// color: #706f6f;
	margin-top: 54rem;

}
.foot {
	width: 100%;
	height: 360rem;
	background-color: #f2f2f2;
	margin-top: 90rem;
	//display: flex;
}
.conterter {
	width: 1280rem;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	cursor: pointer;
}

.conter {
	width: 1366rem;
	height: 168rem;
	font-size: 81rem;

	
	//  margin-left: 50%;
    margin: 0 auto;
}
.zhuxi {
	color: #355ca1;
	font-size: 50rem;
	margin-top: -86rem;
	text-align: center;
}
.shidai{
    font-size: 20rem;
    line-height: 48rem; 
    color: #ffff;
     margin-left: 102rem;
}
.toum {
	margin-top: 165rem;
	width: 445rem;
	height: 48rem;
	background: url(../assets/toum.png);
	margin-left: -600rem;
}
.zhenji {
	padding-top: 58rem;
	font-size: 37rem;
	color: #ffffff;
	margin-left: 63rem;
}
.dahui {
	display: flex;
	font-size: 57rem;
	color: #ffffff;
	margin-left: 462rem;
	padding-top: 44rem;
}
.jing {
	display: flex;
	// width: 1920rem;
	height: 400rem;
	background: url(../assets/xw.png);
}

.yingwen {
	text-align: center;
}

.header {
	width: 1920rem;
	// height: 115rem;
	// background-color: pink;
	display: flex;
}
.imger {
	width: 116rem;
	height: 79rem;
	margin-left: 321rem;
	margin-top: 18rem;
}
.header_png {
	width: 757rem;
	justify-content: space-around;
	display: flex;
	margin-top: 45rem;
	font-size: 20rem;
	 cursor:pointer;
}
.item:hover {
	// padding-top: 5rem;
	border-bottom: 2px solid red;
	color: red;
}
.juxingimg {
	width: 139rem;
	height: 41rem;
	margin-left: 88rem;
	margin-top: 37rem;
}
.biankuang {
	width: 139rem;
	height: 41rem;
	margin-top: 37rem;
	margin-left: 131rem;
	background-color: #f2f2f2;
	border-radius: 20rem 20rem 20rem 20rem;
	display: flex;
}
.yuyanimg {
	margin-left: 8rem;
	width: 25rem;
	height: 25rem;
	margin-top: 7rem;
}
.zhuxi {
	color: #355ca1;
	font-size: 50rem;
	// text-align: center;
}
.item1 {
	width: 188rem;
	margin-right: 176rem;
	margin-bottom: 25rem;
}
.item1:nth-child(4n) {
	margin-right: 0rem;
}
.ruyuan {
	background: url(../assets/ruyuan.png) no-repeat;
	width: 200rem;
	// border-radius: 50%;
	background-size: 100%;
}
</style>
